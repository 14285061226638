import React, {
  ChangeEvent,
  useEffect,
  useState,
  useImperativeHandle,
} from 'react';

import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Typography,
  TableBody,
  TableRow,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Chip,
  SelectChangeEvent,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import NumericInput from '../form-fields/NumericInput';

interface Props {
  feedbackValues: any;
  onsubmit: (values: any) => void;
}

interface ExposedMethods {
  handleFeedbackSubmit: () => void;
}

const calculatePercentage = (ectc: number, cctc: number) => {
  if (typeof cctc !== 'number' || cctc <= 0 || isNaN(cctc)) {
    return 0;
  }

  // Ensure ectc is a valid number
  if (typeof ectc !== 'number' || isNaN(ectc)) {
    return 0;
  }

  // Perform the calculation
  const result = Math.round(((ectc - cctc) / cctc) * 100);

  // Handle negative percentage
  return result >= 0 ? result : 0;
};

const FeebackJSONForm = React.forwardRef<ExposedMethods, Props>(
  ({ feedbackValues, onsubmit }, ref) => {
    const [formValues, setFormValues] = useState<{
      experience: {
        years: number;
        months: number;
      };
      totalCTC: {
        lakh: number;
        thousand: number;
      };
      currentCTC: {
        lakh: number;
        thousand: number;
      };
      expectedCTC: {
        lakh: number;
        thousand: number;
      };
      noticePeriod: number;
      'Reason of Change': string | '';
      Location: string | '';
      currentCompany: string | '';
      currentDesignation: string | '';
      highestQualification: string | '';
      Remark: string | '';
      ESOPS: string | '';
      'Notice Negotiable': boolean | undefined;
      'Salary Negotiable': boolean | undefined;
    }>({
      experience: {
        years: 0,
        months: 0,
      },
      totalCTC: {
        lakh: 0,
        thousand: 0,
      },
      currentCTC: {
        lakh: 0,
        thousand: 0,
      },
      expectedCTC: {
        lakh: 0,
        thousand: 0,
      },
      noticePeriod: 0,
      Location: '',
      currentCompany: '',
      currentDesignation: '',
      highestQualification: '',
      'Reason of Change': '',
      Remark: '',
      ESOPS: '',
      'Notice Negotiable': false,
      'Salary Negotiable': false,
    });
    const [hike, setHike] = useState<number>(0);

    useEffect(() => {
      setFormValues((prev) => ({
        ...prev,
        experience: {
          years: feedbackValues['Total Experience']
            ? Math.floor(feedbackValues['Total Experience'] / 12)
            : 0,
          months: feedbackValues['Total Experience']
            ? feedbackValues['Total Experience'] % 12
            : 0,
        },
        totalCTC: {
          lakh: feedbackValues['totalCTC']
            ? Math.floor(feedbackValues['totalCTC'] / 100000)
            : 0,
          thousand: feedbackValues['totalCTC']
            ? (feedbackValues['totalCTC'] % 100000) / 1000
            : 0,
        },
        currentCTC: {
          lakh: feedbackValues['Current CTC']
            ? Math.floor(feedbackValues['Current CTC'] / 100000)
            : 0,
          thousand: feedbackValues['Current CTC']
            ? (feedbackValues['Current CTC'] % 100000) / 1000
            : 0,
        },
        expectedCTC: {
          lakh: feedbackValues['Expected CTC']
            ? Math.floor(feedbackValues['Expected CTC'] / 100000)
            : 0,
          thousand: feedbackValues['Expected CTC']
            ? (feedbackValues['Expected CTC'] % 100000) / 1000
            : 0,
        },
        noticePeriod: feedbackValues['Notice Period'] || 0,
        Location: feedbackValues['Location'] || '',
        currentCompany: feedbackValues['Current Company'] || '',
        currentDesignation: feedbackValues['Current Designation'] || '',
        highestQualification: feedbackValues['Highest Qualification'] || '',
        'Reason of Change': feedbackValues['Reason of Change'] || '',
        Remark: feedbackValues['Remark'] || '',
        ESOPS: feedbackValues['ESOPS'] || '',
        'Notice Negotiable': feedbackValues['Notice Negotiable'] || false,
        'Salary Negotiable': feedbackValues['Salary Negotiable'] || false,
      }));
    }, [feedbackValues]);

    const handleChange = (
      e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      name: string
    ) => {
      setFormValues((prev) => ({
        ...prev,
        [name]: e.target.value,
      }));
    };

    useEffect(() => {
      if (formValues.currentCTC && formValues.expectedCTC) {
        const ectc =
          formValues.expectedCTC.lakh * 100000 +
          formValues.expectedCTC.thousand * 1000;
        const cctc =
          formValues.currentCTC.lakh * 100000 +
          formValues.currentCTC.thousand * 1000;
        setHike(calculatePercentage(ectc, cctc));
      }
    }, [
      formValues.currentCTC.lakh,
      formValues.currentCTC.thousand,
      formValues.expectedCTC.lakh,
      formValues.expectedCTC.thousand,
    ]);

    const handleNumberChange = (value: number | '', name: string) => {
      setFormValues((prev) => {
        const keys = name.split('.');
        const updatedValues = { ...prev };
        let current: any = updatedValues;
        for (let i = 0; i < keys.length - 1; i++) {
          if (!current[keys[i]]) {
            current[keys[i]] = {};
          }
          current = current[keys[i]];
        }

        current[keys[keys.length - 1]] = value;

        return updatedValues;
      });
    };

    const onNoticePeriodChipClick = (value: number) => {
      setFormValues((prev) => ({
        ...prev,
        noticePeriod: value,
      }));
    };

    const handleFeedbackSubmit = () => {
      // Map formvalues to DBValues
      const feedback = {
        'Total Experience':
          formValues.experience.years * 12 + formValues.experience.months,
        totalCTC:
          formValues.totalCTC.lakh * 100000 +
          formValues.totalCTC.thousand * 1000,
        'Current CTC':
          formValues.currentCTC.lakh * 100000 +
          formValues.currentCTC.thousand * 1000,
        'Expected CTC':
          formValues.expectedCTC.lakh * 100000 +
          formValues.expectedCTC.thousand * 1000,
        'Notice Period': formValues.noticePeriod,
        Location: formValues.Location,
        'Current Company': formValues.currentCompany,
        'Current Designation': formValues.currentDesignation,
        'Highest Qualification': formValues.highestQualification,
        'Reason of Change': formValues['Reason of Change'],
        Remark: formValues.Remark,
        ESOPS: formValues['ESOPS'],
        'Notice Negotiable': formValues['Notice Negotiable'],
        'Salary Negotiable': formValues['Salary Negotiable'],
      };
      onsubmit(feedback);
    };

    useImperativeHandle(ref, () => ({
      handleFeedbackSubmit,
    }));

    const handleLocationSuggestionChip = (value: string) => {
      setFormValues((prev) => ({
        ...prev,
        Location: value,
      }));
    };

    console.log('formValues', formValues);

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableCell colSpan={4}>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Add your feedback
              </Typography>
            </TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell variant="head" width={200}>
                <Typography>Total Experience</Typography>
              </TableCell>
              <TableCell colSpan={3}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  gap={2}
                  alignItems="center"
                >
                  <Box width={120}>
                    <NumericInput
                      label="Years"
                      maxDigits={2}
                      onChange={(value) =>
                        handleNumberChange(value, 'experience.years')
                      }
                      value={formValues.experience.years}
                    />
                  </Box>
                  <Box width={120}>
                    <FormControl sx={{ minWidth: 120 }}>
                      <InputLabel id="feedback-exp-month-label">
                        Months
                      </InputLabel>
                      <Select
                        labelId="feedback-exp-month-label"
                        id="feedback-exp-month"
                        label="Months"
                        size="small"
                        value={`${formValues.experience.months}`}
                        onChange={(event: SelectChangeEvent) =>
                          handleNumberChange(
                            Number(event.target.value),
                            'experience.months'
                          )
                        }
                      >
                        {Array.from({ length: 13 }).map((_, index) => (
                          <MenuItem value={index} key={`months-${index}`}>
                            {index}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Typography variant="caption">{`${formValues.experience.years} years ${formValues.experience.months} months`}</Typography>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                <Typography>Variable CTC</Typography>
              </TableCell>
              <TableCell colSpan={3}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  gap={2}
                  alignItems="center"
                >
                  <Box width={120}>
                    <NumericInput
                      label="Lakh"
                      maxDigits={3}
                      onChange={(value) =>
                        handleNumberChange(value, 'totalCTC.lakh')
                      }
                      value={formValues.totalCTC.lakh}
                    />
                  </Box>
                  <Box width={120}>
                    <NumericInput
                      label="Thousand"
                      onChange={(value) =>
                        handleNumberChange(value, 'totalCTC.thousand')
                      }
                      value={formValues.totalCTC.thousand}
                    />
                  </Box>
                  <Typography variant="caption">{`${formValues.totalCTC.lakh} lakh ${formValues.totalCTC.thousand} thousand`}</Typography>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                <Typography>ESOPs</Typography>
              </TableCell>
              <TableCell colSpan={3}>
                <TextField
                  label="ESOPs"
                  size="small"
                  value={formValues.ESOPS}
                  onChange={(e) => handleChange(e, 'ESOPS')}
                  fullWidth
                  sx={{
                    width: 200,
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                <Typography>Current CTC</Typography>
              </TableCell>
              <TableCell colSpan={3}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  gap={2}
                  alignItems="center"
                >
                  <Box width={120}>
                    <NumericInput
                      label="Lakh"
                      maxDigits={3}
                      onChange={(value) =>
                        handleNumberChange(value, 'currentCTC.lakh')
                      }
                      value={formValues.currentCTC.lakh}
                    />
                  </Box>
                  <Box width={120}>
                    <NumericInput
                      label="Thousand"
                      onChange={(value) =>
                        handleNumberChange(value, 'currentCTC.thousand')
                      }
                      value={formValues.currentCTC.thousand}
                    />
                  </Box>
                  <Typography variant="caption">{`${formValues.currentCTC.lakh} lakh ${formValues.currentCTC.thousand} thousand`}</Typography>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                <Typography>Expected CTC</Typography>
              </TableCell>
              <TableCell width={120} colSpan={3}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  gap={2}
                  alignItems="center"
                >
                  <Box width={120}>
                    <NumericInput
                      label="Lakh"
                      maxDigits={3}
                      onChange={(value) =>
                        handleNumberChange(value, 'expectedCTC.lakh')
                      }
                      value={formValues.expectedCTC.lakh}
                    />
                  </Box>
                  <Box width={120}>
                    <NumericInput
                      label="Thousand"
                      onChange={(value) =>
                        handleNumberChange(value, 'expectedCTC.thousand')
                      }
                      value={formValues.expectedCTC.thousand}
                    />
                  </Box>
                  <Typography variant="caption">{`${formValues.expectedCTC.lakh} lakh ${formValues.expectedCTC.thousand} thousand (${hike}% Hike)`}</Typography>
                </Stack>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formValues['Salary Negotiable']}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setFormValues((prev) => ({
                          ...prev,
                          'Salary Negotiable': event.target.checked,
                        }));
                      }}
                      size="small"
                    />
                  }
                  label="Negotiable?"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                <Typography>Notice Period</Typography>
              </TableCell>
              <TableCell colSpan={3}>
                <Box width={85}>
                  <NumericInput
                    label="Days"
                    onChange={(value) =>
                      handleNumberChange(value, 'noticePeriod')
                    }
                    value={formValues.noticePeriod}
                  />
                </Box>

                <Stack direction="row" spacing={1} mt={0.5}>
                  <Chip
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ height: '17px', cursor: 'pointer' }}
                    onClick={() => onNoticePeriodChipClick(0)}
                    label={
                      <Typography variant="caption">
                        Immediate Joiner
                      </Typography>
                    }
                  />
                  <Chip
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ height: '17px', cursor: 'pointer' }}
                    onClick={() => onNoticePeriodChipClick(15)}
                    label={<Typography variant="caption">15 days</Typography>}
                  />
                  <Chip
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ height: '17px', cursor: 'pointer' }}
                    onClick={() => onNoticePeriodChipClick(30)}
                    label={<Typography variant="caption">30 days</Typography>}
                  />
                  <Chip
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ height: '17px', cursor: 'pointer' }}
                    onClick={() => onNoticePeriodChipClick(45)}
                    label={<Typography variant="caption">45 days</Typography>}
                  />
                </Stack>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formValues['Notice Negotiable']}
                      size="small"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setFormValues((prev) => ({
                          ...prev,
                          'Notice Negotiable': event.target.checked,
                        }));
                      }}
                    />
                  }
                  label="Negotiable?"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                <Typography>Location</Typography>
              </TableCell>
              <TableCell colSpan={3}>
                <Box width={150}>
                  <TextField
                    label="Location"
                    size="small"
                    fullWidth
                    value={formValues['Location']}
                    onChange={(e) => handleChange(e, 'Location')}
                  />
                </Box>

                <Stack direction="row" spacing={1} mt={0.5}>
                  <Chip
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ height: '17px', cursor: 'pointer' }}
                    onClick={() => handleLocationSuggestionChip('Delhi/NCR')}
                    label={<Typography variant="caption">Delhi/NCR</Typography>}
                  />
                  <Chip
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ height: '17px', cursor: 'pointer' }}
                    onClick={() => handleLocationSuggestionChip('Noida')}
                    label={<Typography variant="caption">Noida</Typography>}
                  />
                  <Chip
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ height: '17px', cursor: 'pointer' }}
                    onClick={() => handleLocationSuggestionChip('Gurugram')}
                    label={<Typography variant="caption">Gurugram</Typography>}
                  />
                  <Chip
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ height: '17px', cursor: 'pointer' }}
                    onClick={() => handleLocationSuggestionChip('Bangalore')}
                    label={<Typography variant="caption">Bangalore</Typography>}
                  />
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                <Typography>Current Company</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  label="Current Company"
                  size="small"
                  value={formValues['currentCompany']}
                  onChange={(e) => handleChange(e, 'currentCompany')}
                  fullWidth
                  sx={{ width: 200 }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                <Typography>Current Designation</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  label="Current Designation"
                  size="small"
                  value={formValues['currentDesignation']}
                  onChange={(e) => handleChange(e, 'currentDesignation')}
                  fullWidth
                  sx={{ width: 200 }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                <Typography>Highest Qualification</Typography>
              </TableCell>
              <TableCell colSpan={2}>
                <TextField
                  label="Highest Qualification"
                  size="small"
                  value={formValues['highestQualification']}
                  onChange={(e) => handleChange(e, 'highestQualification')}
                  fullWidth
                  sx={{ width: 200 }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                <Typography>Reason of Change</Typography>
              </TableCell>
              <TableCell colSpan={3}>
                <TextField
                  label="Reason of Change"
                  size="small"
                  value={formValues['Reason of Change']}
                  onChange={(e) => handleChange(e, 'Reason of Change')}
                  fullWidth
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                <Typography>Remark</Typography>
              </TableCell>
              <TableCell colSpan={3}>
                <TextField
                  label="Remark"
                  size="small"
                  multiline
                  value={formValues.Remark}
                  onChange={(e) => handleChange(e, 'Remark')}
                  rows={3}
                  fullWidth
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
);

export default FeebackJSONForm;
