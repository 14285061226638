import ListAltIcon from '@mui/icons-material/ListAlt';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import WorkIcon from '@mui/icons-material/Work';
import StorageIcon from '@mui/icons-material/Storage';
import PeopleIcon from '@mui/icons-material/People';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

const icons = {
  ListAltIcon,
  PlaylistAddIcon,
  PeopleAltIcon,
  MoveToInboxIcon,
  WorkIcon,
  CreateNewFolderIcon,
  StorageIcon,
  PeopleIcon,
};

const postings = {
  id: 'postings',
  title: 'Job',
  type: 'group',
  children: [
    {
      id: 'postings-jobs',
      title: 'Jobs',
      type: 'item',
      url: '/workspace/jobs',
      icon: MoveToInboxIcon,
      breadcrumbs: false,
    },
    // {
    //   id: 'postings-create',
    //   title: 'Post a Job',
    //   type: 'item',
    //   url: '/channels/postings/create',
    //   icon: icons.CreateNewFolderIcon,
    //   breadcrumbs: false,
    // }
  ],
};

export default postings;
