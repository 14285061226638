import { memo } from 'react';
import RegularInput from './RegularInput.react';
import MarkdownInput from './MarkdownInput.react';

interface Props {
  mode: 'EDIT' | 'REPLY' | 'COMMENT';
  user: {
    name: string;
    id: number;
    profilePicUrl?: string;
  };
  commentText?: string;
  onSubmit: Function;
  onCancel?: () => void;
  commentParentId?: number | null;
  commentId?: number | null;
  isSubmitting?: boolean;
  isRichTextInput?: boolean;
  mentionUsers?: Array<{ id: number; name: string; email: string }>;
}

const InputField = memo((props: Props) => {
  return props.isRichTextInput ? (
    <MarkdownInput {...props} />
  ) : (
    <RegularInput {...props} />
  );
});

export default InputField;
