import queryString from 'query-string';

import { axiosInstance, makeRequest } from '../../utils/axios.instance';

export default class JobAPI {
  static async fetchPrimaryFunctionalArea(workpaceId: number) {
    return makeRequest('GET', `/api/v1/job/functional-area/${workpaceId}`);
  }

  static async fetchCitiesList() {
    return makeRequest('GET', '/api/v1/job/cities');
  }

  static async stakeholder(jobId: number) {
    return makeRequest('GET', `/api/v2/job/${jobId}/stakeholder`);
  }

  static async hiringWorkflow(jobId: number) {
    return makeRequest('GET', `/api/v2/hiring-workflow?job=${jobId}`);
  }

  static async fetchTodayAssignedJob() {
    return makeRequest(
      'GET',
      '/api/v1/recruiter-activity-tracker/day-assign-job'
    );
  }

  static async fetchRecruiterTodayAssignedJob(recruiterId: number) {
    return makeRequest(
      'GET',
      `/api/v1/recruiter-activity-tracker/admin/day-assign-job/${recruiterId}`
    );
  }

  static async assignJobForDay(jobId: number) {
    return makeRequest(
      'POST',
      '/api/v1/recruiter-activity-tracker/assign-job',
      { jobId }
    );
  }

  static async unassignJobForDay(jobId: number) {
    return makeRequest(
      'DELETE',
      '/api/v1/recruiter-activity-tracker/assign-job',
      { jobId }
    );
  }

  static async fetchJobReport(
    workspaceId: number,
    job: number,
    dateRange: { start: string; end: string }
  ) {
    return makeRequest(
      'GET',
      `/api/v1/dashboard/report/${workspaceId}/${job}/cv-moved-through?start=${dateRange.start}&end=${dateRange.end}`
    );
  }

  static async fetchJobReportApplication(
    job: number,
    slug: string,
    dateRange: { start: string; end: string },
    page = 1
  ) {
    return makeRequest(
      'GET',
      `/api/v3/job-application/${job}/by-event-type?eventType=${slug}&page=${page}&start=${dateRange.start}&end=${dateRange.end}`
    );
  }

  static async fetchRecruiterAssigned(
    workspaceId: number,
    page: number = 1,
    selfhosted: boolean = false,
    status?: any,
    searchText?: string
  ) {
    const query = queryString.stringify(
      { page, selfhosted, status, searchText },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    return makeRequest('GET', `/api/v2/job/${workspaceId}?${query}`);
  }

  static async fetchWorkspaceChannelJobs(
    workspaceId: number,
    channelId: number
  ) {
    return makeRequest(
      'GET',
      `/api/v2/job/job-suggestion/${workspaceId}/${channelId}`
    );
  }

  // Todo: Need to update as above
  static async updateJobStatus(jobId: number, status: string) {
    return axiosInstance({
      method: 'PATCH',
      url: `/api/v1/job/status/${jobId}`,
      data: {
        status,
      },
    });
  }

  static async addStakeholdersToJob(
    payload: Array<{ recruiterId: number; jobId: number }>
  ) {
    return axiosInstance({
      method: 'PUT',
      url: '/api/v1/job/add-stakeholder',
      data: payload,
    });
  }

  static async addStakeholdersToApplication(
    payload: Array<{ recruiterId: number; applicationId: number }>
  ) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/job-application/assign-recruiter',
      data: payload,
    });
  }

  static async fetchAssignedJobs() {
    return axiosInstance({
      method: 'GET',
      url: '/api/v1/job/recruiter-active-jobs',
    });
  }
}
