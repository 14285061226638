import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { MailerServiceAPI } from '../../services/mailer.service';

export const fetchTemplates = createAsyncThunk(
  'mailer-templates/fetch',
  async (args: { workspaceId: number; search?: string }, { getState }) => {
    const channel = localStorage.getItem('baseChannel') || null;
    if (channel) {
      const { id = '' } = JSON.parse(channel);
      const response = await MailerServiceAPI.getTemplates(
        Number(args.workspaceId),
        Number(id),
        args.search
      );
      return response.data;
    }

    return [];
  }
);

export const createTemplate = createAsyncThunk(
  'mailer-template/create',
  async (
    args: { title: string; content: string; visibility?: 'public' | 'private' },
    { getState }
  ) => {
    const {
      client: { selectedClient: { id = '', workspaceId = '' } = {} } = {},
    } = getState() as RootState;

    const response = await MailerServiceAPI.addTemplate({
      title: args.title,
      content: args.content,
      visibility: args.visibility,
      workspaceId: Number(workspaceId),
      channelId: Number(id),
    });
    return response.data;
  }
);

interface IState {
  templates: {
    loading: 'pending' | 'failed' | 'succeeded' | 'idle';
    data: Array<any>;
  };
}

const initialState: IState = {
  templates: {
    loading: 'idle',
    data: [],
  },
};

export const mailerSlice = createSlice({
  name: 'mailer',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addMatcher(
        isAnyOf(fetchTemplates.pending, createTemplate.pending),
        (state) => {
          state.templates.loading = 'pending';
        }
      )
      .addMatcher(
        isAnyOf(fetchTemplates.fulfilled, createTemplate.fulfilled),
        (state, action) => {
          state.templates.loading = 'succeeded';
          state.templates.data = action.payload;
        }
      )
      .addMatcher(
        isAnyOf(fetchTemplates.rejected, createTemplate.rejected),
        (state) => {
          state.templates.loading = 'failed';
        }
      ),
});

export default mailerSlice.reducer;
