import queryString from 'query-string';

import { makeRequest } from '../../utils/axios.instance';

export default class DashboardAPI {
  static async fetchTotalCV(
    workspaceId: number,
    start: string,
    end: string
  ): Promise<number> {
    const query = queryString.stringify(
      {
        start,
        end,
      },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    return await makeRequest(
      'GET',
      `/api/v1/dashboard/report/${workspaceId}/cv-sourced?${query}`
    );
  }

  static async fetchOfferAndJoined(
    workspaceId: number,
    start: string,
    end: string
  ): Promise<{ offer: number; joined: number }> {
    const query = queryString.stringify(
      {
        start,
        end,
      },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    return await makeRequest(
      'GET',
      `/api/v1/dashboard/report/${workspaceId}/cv-output?${query}`
    );
  }

  static async fetchCVDistribution(
    workspaceId: number,
    start: string,
    end: string
  ) {
    const query = queryString.stringify(
      {
        start,
        end,
      },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    return await makeRequest(
      'GET',
      `/api/v1/dashboard/report/${workspaceId}/sourced-cv-distribution?${query}`
    );
  }

  static async fetchCVGroupStage(
    workspaceId: number,
    start: string,
    end: string
  ) {
    const query = queryString.stringify(
      {
        start,
        end,
      },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    return await makeRequest(
      'GET',
      `/api/v1/dashboard/report/${workspaceId}/cv-moved-through?${query}`
    );
  }

  static async fetchApplicationBreakup(
    workspaceId: any,
    start: string,
    end: string,
    id: any,
    type: string
  ) {
    const query = queryString.stringify(
      {
        start,
        end,
        id,
        type,
      },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    return await makeRequest(
      'GET',
      `/api/v1/dashboard/report/${workspaceId}/application-breakup?${query}`
    );
  }
}
