import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import WorkspaceMailAPI from '../../services/workspace-mail.service';

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

interface IState {
  loading: ILoading;
  error: string;
  data: {
    total: number;
    pageSize: number;
    page: number;
    mails: Array<any>;
  } | null;
}

const fetchSentMail = createAsyncThunk(
  'mail-sent/fetch-sent',
  async (payload: { page?: number }): Promise<any> => {
    const { page = 1 } = payload;
    return await WorkspaceMailAPI.getSentEmail(page);
  }
);

const initialState: IState = {
  loading: 'idle',
  error: '',
  data: null,
};

const sentSlice = createSlice({
  name: 'mail-inbox',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchSentMail.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchSentMail.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchSentMail.rejected, (state) => {
        state.loading = 'failed';
      }),
});

export default sentSlice.reducer;

export { fetchSentMail };
