import { combineReducers } from 'redux';

import CVSourced from './cvSourceSlice';
import offerAndJoinedSlice from './offerAndJoinedSlice';
import cvDistributionSlice from './cvDistributionSlice';
import cvGroupStageSlice from './cvGroupStageSlice';

const DashboardReducer = combineReducers({
  CVSourced: CVSourced,
  offerAndJoined: offerAndJoinedSlice,
  cvDistribution: cvDistributionSlice,
  cvGroupStage: cvGroupStageSlice,
});

export default DashboardReducer;
