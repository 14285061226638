import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CandidateAPI from '../../services/candidate.service';

interface CandidateTags {
  labelOptions: {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string;
    data: Array<{
      id: number;
      name: string;
      color: string;
    }>;
  };
  labels: {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string;
    data: Array<{
      id: number;
      name: string;
      color?: string;
    }>;
  };
}

const fetchTagList = createAsyncThunk(
  'candidate-label/fetch-taglist',
  async (): Promise<any> => {
    return await CandidateAPI.tagsList();
  }
);

const createLabel = createAsyncThunk(
  'candidate-label/add-tags',
  async (payload: { name: string; color?: string }, { dispatch }) => {
    await CandidateAPI.createLabel(payload);
    dispatch(fetchTagList());
  }
);

const assignLabel = createAsyncThunk(
  'candidate-label/assign-label',
  async (
    payload: Array<{
      applicantId: number;
      labelId: number;
      isPrivate?: boolean;
    }>
  ) => {
    return await CandidateAPI.assignLabel(payload);
  }
);

const assignedLabel = createAsyncThunk(
  'candidate-label/assigned-label',
  async (payload: { applicantId: number }): Promise<any> => {
    return await CandidateAPI.assignedLabel(payload.applicantId);
  }
);

const removeLabel = createAsyncThunk(
  'candidate-label/assign-label',
  async (payload: { applicantId: number; labelId: number }, { dispatch }) => {
    return await CandidateAPI.removeLabel(payload);
  }
);

const initialState: CandidateTags = {
  labelOptions: {
    loading: 'idle',
    error: '',
    data: [],
  },
  labels: {
    loading: 'idle',
    error: '',
    data: [],
  },
};

const candidateTagsSlice = createSlice({
  name: 'candidate-label',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchTagList.pending, (state) => {
        state.labelOptions.loading = 'pending';
      })
      .addCase(fetchTagList.fulfilled, (state, action) => {
        state.labelOptions.loading = 'succeeded';
        state.labelOptions.data = action.payload;
      })
      .addCase(fetchTagList.rejected, (state) => {
        state.labelOptions.loading = 'failed';
      })
      .addCase(assignedLabel.pending, (state) => {
        state.labels.loading = 'failed';
      })
      .addCase(assignedLabel.fulfilled, (state, action) => {
        state.labels.loading = 'succeeded';
        state.labels.data = action.payload;
      }),
});

export default candidateTagsSlice.reducer;

export { fetchTagList, createLabel, assignLabel, removeLabel, assignedLabel };
