import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import WorkspaceMailAPI from '../../services/workspace-mail.service';

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

interface IState {
  loading: ILoading;
  error: string;
  data: {
    total: number;
    pageSize: number;
    page: number;
    mails: Array<any>;
  } | null;
}

interface ILabelState {
  labelMails: IState;
  labels: {
    loading: ILoading;
    error: string;
    data: Array<{
      id: number;
      name: string;
      color: string;
    }>;
  };
}

const fetchLabelMail = createAsyncThunk(
  'mail-label/fetch-label-mails',
  async (payload: { labelId: number; page?: number }): Promise<any> => {
    const { labelId, page = 1 } = payload;
    return await WorkspaceMailAPI.getLabelEmail(labelId, page);
  }
);

const fetchLabels = createAsyncThunk(
  'mail-label/fetch-label',
  async (): Promise<any> => {
    return await WorkspaceMailAPI.getLabels();
  }
);

const createLabel = createAsyncThunk(
  'mail-label/create-label',
  async (payload: { name: string; color?: string }, { rejectWithValue }) => {
    try {
      return await WorkspaceMailAPI.createLabel(payload);
    } catch (error: any) {
      // Extract request and response from Axios error
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        data: error.response?.data,
      });
    }
  }
);

const initialState: ILabelState = {
  labelMails: {
    loading: 'idle',
    error: '',
    data: null,
  },
  labels: {
    loading: 'idle',
    error: '',
    data: [],
  },
};

const labelSlice = createSlice({
  name: 'mail-label',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchLabelMail.pending, (state) => {
        state.labelMails.loading = 'pending';
      })
      .addCase(fetchLabelMail.fulfilled, (state, action) => {
        state.labelMails.loading = 'succeeded';
        state.labelMails.data = action.payload;
      })
      .addCase(fetchLabelMail.rejected, (state) => {
        state.labelMails.loading = 'failed';
      })
      .addCase(fetchLabels.pending, (state) => {
        state.labels.loading = 'pending';
      })
      .addCase(fetchLabels.fulfilled, (state, action) => {
        state.labels.loading = 'succeeded';
        state.labels.data = action.payload;
      })
      .addCase(fetchLabels.rejected, (state) => {
        state.labels.loading = 'failed';
      }),
});

export default labelSlice.reducer;

export { fetchLabelMail, fetchLabels, createLabel };
