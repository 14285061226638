import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ReportAPI } from '../../services/report-api.service';

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

interface IState {
  loading: ILoading;
  data: {
    hasMore: boolean;
    list: Array<any>;
    total: number;
  };
}

export const fetchVelocityApplications = createAsyncThunk(
  'reportApplicationSlice/fetchVelocityApplications',
  async (payload: {
    type: string;
    category: 'distribution' | 'movement';
    id: string | number;
    start: string;
    end: string;
    page: number;
    stage?: string;
  }): Promise<any> => {
    return ReportAPI.reportVelocityApplications(
      payload.type,
      payload.category,
      payload.id,
      payload.start,
      payload.end,
      payload.page,
      payload.stage
    );
  }
);

const initialState: IState = {
  loading: 'idle',
  data: {
    hasMore: false,
    list: [],
    total: 0,
  },
};

const reportApplicationSlice = createSlice({
  name: 'reportApplicationSlice',
  initialState,
  reducers: {
    resetList: (state) => {
      state.loading = 'idle';
      state.data.list = [];
      state.data.total = 0;
      state.data.hasMore = false;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchVelocityApplications.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchVelocityApplications.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data.list = [...state.data.list, ...action.payload.list];
        state.data.total = action.payload.total;
        state.data.hasMore = action.payload.hasMore;
      })
      .addCase(fetchVelocityApplications.rejected, (state) => {
        state.loading = 'failed';
      }),
});

export default reportApplicationSlice.reducer;

export const { resetList } = reportApplicationSlice.actions;
