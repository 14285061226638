import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Hidden, Typography, Link } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import _ from 'lodash';

import { drawerWidth } from '../../../utils/constants';
import MenuList from './menu-list';
import { useAppSelector } from '../../../hooks/redux';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
// menu data
import dashboard from './data/dashboard';
import settings from './data/settings';
import canidateNavItem from './data/candidates';
import channels from './data/channel';
import postings from './data/postings';

import DownloadAppCTA from '../../download-app-CTA';
import { getFavouriteClients } from '../../../redux/features/dashboard/clientSlice';
import { getNameFromDomain } from '../../../utils/helper-functions';

const Sidebar = ({ drawerOpen, drawerToggle, permissions = {} }: any) => {
  const [channelItems, setChannelItems] = useState(channels);
  const theme = useTheme();
  const favouriteChannel = useAppSelector(getFavouriteClients);

  useEffect(() => {
    if (favouriteChannel.length) {
      const favouriteChannelItems = favouriteChannel.map((channel: any) => ({
        id: channel.name,
        title: getNameFromDomain(channel.name),
        type: 'button',
        url: 'channels',
        data: channel,
        icon: StarIcon,
        breadcrumbs: false,
      }));
      setChannelItems((prev) => {
        // Create a set of the current `favouriteChannel` IDs
        const favouriteChannelIds = new Set(
          favouriteChannel.map((ch: any) => ch.name)
        );

        // Filter `prev.children` to keep only items that are still in `favouriteChannel`
        const filteredChildren = prev.children.filter((child: any) =>
          favouriteChannelIds.has(child.id)
        );

        // Add new unique items
        const existingIds = new Set(
          filteredChildren.map((child: any) => child.id)
        );
        const uniqueItems = favouriteChannelItems.filter(
          (item) => !existingIds.has(item.id)
        );

        return {
          ...prev,
          children: [
            {
              id: 'all-channel',
              title: 'All Channels',
              type: 'item',
              url: 'channels',
              icon: CorporateFareIcon,
              breadcrumbs: false,
            },
            ...filteredChildren,
            ...uniqueItems,
          ],
        };
      });
    }
  }, [favouriteChannel.length]);

  //@ts-ignore
  const isElectron = window && window.process && window.process.type;

  const drawer = (
    <>
      <Box
        sx={{
          display: 'flex',
          px: 2,
          pt: '10px',
          pb: '15px',
          mx: 'auto',
          alignItems: 'center',
        }}
      >
        <Link href="/#/" underline="none" data-component-name="DrawerLogo">
          <Typography
            variant="h3"
            sx={{
              fontFamily: 'Montserrat',
              color: '#ff6451',
            }}
          >
            HireXL
          </Typography>
        </Link>
      </Box>
      <Box sx={{ px: 2 }}>
        <MenuList menuItems={[dashboard]} permissions={permissions} />
        <MenuList menuItems={[channelItems]} permissions={permissions} />
        <MenuList menuItems={[postings]} permissions={permissions} />
        <MenuList menuItems={[canidateNavItem]} permissions={permissions} />
        <MenuList menuItems={[settings]} permissions={permissions} />
      </Box>

      {!isElectron && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body2">Try Our Desktop App</Typography>
          <DownloadAppCTA />
        </Box>
      )}
    </>
  );

  return (
    <Box component="nav" sx={{ flexShrink: 0 }}>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="left"
          open={drawerOpen}
          onClose={drawerToggle}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          onClose={drawerToggle}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              background: theme.palette.background.paper,
              color: theme.palette.text.primary,
              borderRight: 'none',
              overflowY: 'hidden',
              '&:hover': {
                overflowY: 'auto',
              },
            },
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </Box>
  );
};

export default Sidebar;
