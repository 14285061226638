import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from './redux';
import {
  candidateById,
  updateApplicant,
} from '../redux/features/applications/applicationSlice';
import {
  fetchCandidateTimeline,
  candidateStageTimeline,
} from '../redux/features/candidate/timeline.slice';
import { fetchComments } from '../redux/features/comments/comment.slice';
import {
  getFeedbacks,
  resetEvaluvationSubmit,
} from '../redux/features/jobs/evaluation-template.slice';

export const useCandidateDetails = (applicationId?: number | null) => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const jobApplicationId = params.get('candidateId') || applicationId;

  const { candidate } = useAppSelector((state) => state.application);
  const candidateTimeline = useAppSelector((state) => state.candidateTimeline);
  const stageChangeList = useAppSelector(candidateStageTimeline);
  const { evaluations } = useAppSelector((state) => state.evaluation);
  const { commentList } = useAppSelector((state) => state.comment);
  const [feedbackValues, setFeedbackValues] = useState<Record<string, any>>({});
  const [candidateInfoActiveTab, setCandidateInfoActiveTab] = useState('0');

  useEffect(() => {
    if (jobApplicationId) {
      dispatch(candidateById(Number(jobApplicationId)));
      dispatch(fetchCandidateTimeline(Number(jobApplicationId)));
      dispatch(getFeedbacks(Number(jobApplicationId)));
      dispatch(fetchComments(Number(jobApplicationId)));
    }
  }, [jobApplicationId]);

  useEffect(() => {
    return () => {
      dispatch(resetEvaluvationSubmit());
    };
  }, []);

  useEffect(() => {
    if (evaluations.data.length) {
      const values: Record<string, any> = {};
      evaluations.data[0].forEach((feedback: any) => {
        values[feedback.label] = feedback.value;
      });
      setFeedbackValues(values);
    } else {
      setFeedbackValues({});
    }
  }, [evaluations]);

  return {
    candidate,
    candidateTimeline,
    stageChangeList,
    feedbackValues,
    commentList,
    candidateInfoActiveTab,
    setFeedbackValues,
    setCandidateInfoActiveTab,
    updateApplicant: (data: any) => dispatch(updateApplicant(data)),
  };
};
