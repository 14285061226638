import React, { memo, useCallback, useState, useEffect, useMemo } from 'react';
import { Stack, Tooltip, Avatar, Button } from '@mui/material';

import { stringAvatar, stringToColor } from '../../../utils/helper-functions';
import MarkdownEditor from '../../WYSWYGEditor/markdown-editor';

interface MarkdownInputProps {
  mode: 'EDIT' | 'REPLY' | 'COMMENT';
  user: {
    name: string;
    id: number;
    profilePicUrl?: string;
  };
  commentText?: string;
  onCancel?: () => void;
  onSubmit: Function;
  commentParentId?: number | null;
  commentId?: number | null;
  isSubmitting?: boolean;
  mentionUsers?: Array<{ id: number; name: string; email: string }>;
}

const MarkdownInput: React.FC<MarkdownInputProps> = memo(
  ({
    mode = 'COMMENT',
    user,
    commentParentId = null,
    commentId = null,
    commentText = '',
    onSubmit,
    isSubmitting = false,
    onCancel,
    mentionUsers = [],
  }) => {
    const [content, setContent] = useState<string>('');

    useEffect(() => {
      setContent(commentText);
    }, [commentText]);

    useEffect(() => {
      if (!isSubmitting && mode == 'COMMENT') {
        setContent('');
      }
    }, [isSubmitting, mode]);

    const handleSubmit = useCallback(
      (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(mode, content, commentId, commentParentId);
      },
      [commentParentId, content]
    );

    const handleContentChange = (content: string) => {
      setContent(content);
    };

    const mentionOptions = useMemo(() => {
      return mentionUsers.map((user) => ({
        id: user.email,
        label: user.name,
      }));
    }, [mentionUsers]);

    return (
      <Stack
        component="form"
        direction="row"
        alignItems="center"
        gap={1}
        width="100%"
        onSubmit={handleSubmit}
      >
        <Tooltip title={user.name}>
          <Avatar
            {...stringAvatar(user.name)}
            sx={{
              width: 30,
              height: 30,
              fontSize: '12px',
              bgcolor: stringToColor(user.name),
            }}
          />
        </Tooltip>
        <MarkdownEditor
          onChange={handleContentChange}
          mentionOptions={mentionOptions}
          content={content}
        />
        {mode != 'COMMENT' && (
          <Button variant="outlined" size="small" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button
          variant="outlined"
          size="small"
          type="submit"
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Stack>
    );
  }
);

export default MarkdownInput;
