import queryString from 'query-string';
import { axiosInstance, makeRequest } from '../../utils/axios.instance';

export default class JobApplicationAPI {
  static async rejectCandidate(
    id: number,
    rejected: 'Y' | 'N',
    rejectionReason?: string
  ) {
    return axiosInstance({
      url: '/api/v1/job-application/reject',
      method: 'PUT',
      data: {
        id,
        rejected,
        rejectionReason,
      },
    });
  }

  static async fetchCandidate(id: number) {
    return axiosInstance({
      method: 'GET',
      url: `/api/v2/job-application/candidate-info/${id}`,
    });
  }

  static async fetchJobSnapshot(id: number) {
    return axiosInstance({
      method: 'GET',
      url: `/api/v2/job-application/snapshot/${id}`,
    });
  }

  static async assignFromTalentPool(
    jobs: Array<number | undefined>,
    candidateId: Array<number>
  ) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v3/job-application/assign-from-talent-pool',
      data: {
        candidate: candidateId,
        jobs,
      },
    });
  }

  static async updateApplicant(formData: any) {
    return axiosInstance({
      method: 'PUT',
      url: '/api/v1/job-application/applicant',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
  }

  static async fetchActiveApplication(payload: {
    workspaceId: number;
    page: number;
    searchTerm?: string;
    stage?: string;
    channelId?: number;
    recruiterId?: number;
  }) {
    const {
      workspaceId,
      page = 1,
      searchTerm = '',
      stage = '',
      channelId = '',
      recruiterId = '',
    } = payload;
    const query = queryString.stringify(
      { searchTerm, page, stage, channelId, recruiterId },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    return makeRequest(
      'GET',
      `/api/v2/job-application/${workspaceId}/candidates?${query}`
    );
  }

  static async fetchActiveApplicationSnapshot({
    workspaceId,
    stage = '',
    channelId,
    recruiterId,
  }: {
    workspaceId: number;
    stage?: string;
    channelId?: number;
    recruiterId?: number;
  }) {
    const query = queryString.stringify(
      { stage, channelId, recruiterId },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    return makeRequest(
      'GET',
      `/api/v2/job-application/${workspaceId}/candidates/snapshots?${query}`
    );
  }

  static async fetchFeedbackTable(data: { application: Array<number> }) {
    return makeRequest('POST', '/api/v1/job-application/feedback-table', data);
  }
}
