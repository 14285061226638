import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import TalentPoolAPIService from '../../services/talent-pool.service';

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

interface IState {
  loading: ILoading;
  error: string;
  data: {
    applications: Array<{
      joiningDate: string | null;
      probationEndDate: string | null;
      createdAt: string;
      job: {
        title: string;
        channel: {
          name: string;
          slug: string;
        };
      };
      hiringStatus: {
        applicationStage: {
          name: string;
          slug: string;
        };
      };
      feedback: Array<any>;
      comment: any;
    }>;
  } | null;
}

export const fetchApplicantHistory = createAsyncThunk(
  'telentPoolApplicantHistory/fetch-applicant-history',
  async (payload: { id: number }): Promise<any> => {
    return await TalentPoolAPIService.fetchApplicantHistory(payload.id);
  }
);

const initialState: IState = {
  loading: 'idle',
  error: '',
  data: null,
};

const applicantHistorySlice = createSlice({
  name: 'telentPoolApplicantHistory',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = 'idle';
      state.data = null;
      state.error = '';
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchApplicantHistory.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchApplicantHistory.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchApplicantHistory.rejected, (state) => {
        state.loading = 'failed';
        state.error = 'something went wrong!';
      }),
});

export const { reset } = applicantHistorySlice.actions;
export default applicantHistorySlice.reducer;
