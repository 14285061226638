import React, { useState, useMemo, useCallback } from 'react';
import {
  Box,
  IconButton,
  Popover,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Button,
  Typography,
} from '@mui/material';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { useFormik } from 'formik';

interface Label {
  id: number;
  name: string;
  color: string;
}

interface LabelPopoverProps {
  labels: Label[];
  activeLabel: Array<{ labelId: number }>;
  onSubmit: (labelIds: number[]) => void;
  onOpenLabelModel: () => void;
}

const LabelPopover: React.FC<LabelPopoverProps> = ({
  labels = [],
  activeLabel = [],
  onSubmit,
  onOpenLabelModel,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // Memoized labels with active state
  const mappedLabels = useMemo(
    () =>
      labels.map((label) => ({
        ...label,
        isActive: activeLabel.some((active) => active.labelId === label.id),
      })),
    [labels, activeLabel]
  );

  // Store original state for change detection
  const initialLabelState = useMemo(
    () => mappedLabels.map((label) => label.isActive),
    [mappedLabels]
  );

  // Formik setup
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { labels: mappedLabels },
    onSubmit: (values) => {
      const labelIds = values.labels
        .filter((l) => l.isActive)
        .map((label) => label.id);
      onSubmit(labelIds);
    },
  });

  // Detect changes between the initial and current state
  const isChanged = useMemo(() => {
    return formik.values.labels.some(
      (label, index) => label.isActive !== initialLabelState[index]
    );
  }, [formik.values.labels, initialLabelState]);

  const open = Boolean(anchorEl);
  const id = open ? 'mail-label-popover' : undefined;

  // Handlers with useCallback to prevent unnecessary re-renders
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(event.currentTarget),
    []
  );

  const handleCheckboxChange = (index: number) => {
    formik.setFieldValue(
      `labels[${index}].isActive`,
      !formik.values.labels[index].isActive
    );
  };

  return (
    <Box>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <LabelOutlinedIcon />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Box p={1} maxHeight={400}>
          <FormGroup>
            {formik.values.labels.map((label, index) => (
              <FormControlLabel
                key={label.id}
                control={
                  <Checkbox
                    size="small"
                    checked={label.isActive}
                    onChange={() => handleCheckboxChange(index)}
                  />
                }
                label={label.name}
              />
            ))}
          </FormGroup>

          <Divider />

          {isChanged && (
            <Button
              variant="text"
              size="small"
              onClick={() => formik.handleSubmit()}
              fullWidth
            >
              <Typography variant="body2">Apply</Typography>
            </Button>
          )}
          <Button
            variant="text"
            size="small"
            fullWidth
            onClick={onOpenLabelModel}
          >
            <Typography variant="body2">Create Label</Typography>
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

export default LabelPopover;
