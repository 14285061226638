import { useEffect, useState } from 'react';
import { Box, Tooltip, Avatar, Stack, Button, IconButton } from '@mui/material';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import parse from 'html-react-parser';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { stringAvatar, stringToColor } from '../../utils/helper-functions';
import InputField from './InputField';
import { likeComment } from '../../redux/features/comments/comment.slice';
import MarkdownHTMLPreview from '../WYSWYGEditor/markdown-editor/MarkdownHTMLPreview';

interface Props {
  comment: {
    commentText: string;
    replies: Array<any>;
    createdAt: string;
    user: any;
    id: any;
    status: string;
    noOfLikes: number;
    isUserLiked: boolean;
  };
  isSubmitting?: boolean;
  user: any;
  onSubmit: Function;
  mentionUsers?: Array<{ id: number; email: string; name: string }>;
}

const Comment = (props: Props) => {
  const {
    commentText = '',
    replies = [],
    createdAt = '',
    user = {},
    id = '',
    status = '',
    noOfLikes = 0,
    isUserLiked = false,
  } = props.comment;
  const { mentionUsers } = props;
  const [openReply, setOpenReply] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const { commentList } = useAppSelector((state) => state.comment);
  const talentPoolCommentList = useAppSelector(
    (state) => state.talentPoolComment.commentList
  );
  const dispatch = useAppDispatch();

  const handleOpenReply = () => {
    setOpenReply((prev) => !prev);
  };

  const handleOpenEditComment = () => {
    setOpenEdit((prev) => !prev);
  };

  useEffect(() => {
    if (
      commentList.loading !== 'pending' ||
      talentPoolCommentList.loading !== 'pending'
    ) {
      setOpenReply(false);
      setOpenEdit(false);
    }
  }, [commentList.loading, talentPoolCommentList.loading]);

  const handleCancel = () => {
    setOpenReply(false);
    setOpenEdit((prev) => false);
  };

  const handleLikeComment = () => {
    dispatch(likeComment({ commentId: id }));
  };

  return (
    <Box
      sx={{
        marginLeft: '20px',
        padding: '0px 0px 0px 10px',
        marginBottom: '10px',
        borderLeft: '2px solid #edeff1',
      }}
    >
      <Stack
        direction="row"
        ml={'-25px'}
        justifyContent="space-between"
        width="100%"
        alignItems="flex-start"
      >
        {openEdit && mentionUsers && mentionUsers?.length > 0 ? (
          <InputField
            mode="EDIT"
            commentText={commentText}
            user={{
              name: props.user.name,
              id: props.user.id,
              profilePicUrl: props.user.profilePic,
            }}
            commentId={id}
            onSubmit={props.onSubmit}
            onCancel={handleCancel}
            mentionUsers={mentionUsers}
            isRichTextInput
          />
        ) : (
          <>
            <Stack direction="row" gap={1} alignItems="flex-start">
              <Tooltip title={user.name}>
                <Avatar
                  {...stringAvatar(user.name)}
                  sx={{
                    width: 30,
                    height: 30,
                    fontSize: '12px',
                    bgcolor: stringToColor(user.name),
                  }}
                />
              </Tooltip>
              <Stack direction="column">
                <div className="comment-text-wrapper">
                  <MarkdownHTMLPreview content={commentText} />
                </div>
                <Tooltip title={moment(createdAt).format('DD MMM, YYYY')}>
                  <Button sx={{ width: 'max-content', fontSize: 12 }}>
                    {moment(createdAt).fromNow()}
                  </Button>
                </Tooltip>
              </Stack>
            </Stack>
            {user.id === props.user.id && (
              <IconButton
                sx={{ marginTop: '-5px' }}
                onClick={handleOpenEditComment}
                size="small"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </>
        )}
      </Stack>

      <Stack direction="row">
        {status != 'ARCHIVED' && (
          <Button
            startIcon={<ChatBubbleOutlineOutlinedIcon />}
            size="small"
            onClick={handleOpenReply}
            sx={{ marginBottom: 1 }}
          >
            Reply
          </Button>
        )}
        <Button
          startIcon={isUserLiked ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
          size="small"
          onClick={handleLikeComment}
          sx={{ marginBottom: 1 }}
        >
          {`${noOfLikes} Likes`}
        </Button>
        {/* <EmojiInput onSetEmoji={handleEmojiReaction} iconSize="small" /> */}
      </Stack>

      {openReply && (
        <InputField
          mode="REPLY"
          user={{
            name: props.user.name,
            id: props.user.id,
            profilePicUrl: props.user.profilePic,
          }}
          commentParentId={id}
          onSubmit={props.onSubmit}
          onCancel={handleCancel}
        />
      )}

      {replies && replies.length > 0 && (
        <Box mt={1}>
          {replies.map((reply) => (
            <Comment
              key={`comment-${reply.id}`}
              comment={reply}
              user={props.user}
              onSubmit={props.onSubmit}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Comment;
