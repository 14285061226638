import React, { useRef, useEffect, useState } from 'react';
import { styled, Theme } from '@mui/material';
import Document from '@tiptap/extension-document';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import TipTapTypography from '@tiptap/extension-typography';
import Text from '@tiptap/extension-text';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Underline from '@tiptap/extension-underline';
import Heading from '@tiptap/extension-heading';
import Link from '@tiptap/extension-link';

import suggestion from './suggestions';

const StyledEditorContent = styled(EditorContent)(
  ({ theme }: { theme: Theme }) => ({
    width: '100%',
    '& .tiptap': {
      minHeight: 50,
      border: '1px solid #CCC',
      background: '#FFFFFF',
      borderRadius: 4,
      padding: 8,
      outline: 'none',
      fontSize: '0.9em',
      '& p.is-empty::before': {
        content: 'attr(data-placeholder)', // Corrected way to apply attribute content
        color: theme.palette.text.secondary, // Uses MUI theme colors
        fontStyle: 'italic',
        pointerEvents: 'none', // Ensures placeholder does not interfere with typing
        display: 'block',
      },
      '& table': {
        width: '100%',
        borderCollapse: 'collapse',
      },
      '& th, & td': {
        border: `1px solid ${theme.palette.divider}`,
        padding: '8px',
        textAlign: 'left',
      },
      '& th': {
        backgroundColor: theme.palette.grey[200],
        fontWeight: 'bold',
      },
      '& tr:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
      '& p': {
        marginBottom: 0,
      },
      '& ul, ol': {
        margin: 'inherit',
        padding: '1em 2em',
        '& p': {
          marginBottom: '0px',
        },
      },
      '& :first-child': {
        marginTop: 0,
      },
      '& .mention': {
        backgroundColor: '#3788D8',
        borderRadius: '0.2rem',
        padding: '0.1rem 0.1rem',
        color: '#FFFFFF',
      },
    },
  })
);

interface Props {
  onChange: (text: string) => void;
  mentionOptions: { id: string; label: string }[];
  content: string;
}

export default ({ onChange, mentionOptions, content = '' }: Props) => {
  const contentSet = useRef(false);

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      TipTapTypography,
      Underline,
      Link,
      Heading.configure({ levels: [1, 2, 3] }),
      StarterKit.configure({
        bulletList: { keepMarks: true, keepAttributes: false },
        orderedList: { keepMarks: true, keepAttributes: false },
      }),
      Mention.configure({
        HTMLAttributes: { class: 'mention' },
        suggestion: suggestion(mentionOptions),
      }),
      Table.configure({ resizable: true }),
      TableRow,
      TableCell,
      TableHeader,
    ],
    content,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor && !content) {
      editor.commands.setContent('');
    }
    if (editor && content && !contentSet.current) {
      editor.commands.setContent(content);
      contentSet.current = true; // Mark as set so it doesn't run again
    }
  }, [editor, content]);

  return <StyledEditorContent editor={editor} />;
};
