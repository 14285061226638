import queryString from 'query-string';

import { axiosInstance, makeRequest } from '../../utils/axios.instance';

export default class TalentPoolAPIService {
  static getApplicant(candidateId: number) {
    return makeRequest('GET', `/api/v1/talent-pool/candidate/${candidateId}`);
  }

  static uploadSingleCandidateRequest = (payload: any) =>
    axiosInstance({
      method: 'POST',
      url: '/api/v1/talent-pool/add-candidate',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: payload,
    });

  static uploadResumeFile(data: any) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/talent-pool/upload-resume',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data,
    });
  }

  static fetchTalentPoolCandidate({ page = 1, filters = {} }: any) {
    const filterData: any = {
      searchTerm: filters.searchTerm || '',
      location: filters.location || '',
      functionalArea: filters.functionalArea
        ? filters.functionalArea.map((functionalArea: any) => functionalArea.id)
        : null,

      isEmailRequired: filters.isEmailRequired || false,
      isLinkedinRequired: filters.isLinkedinRequired || false,
      isPhoneRequired: filters.isPhoneRequired || false,
      isResumeRequired: filters.isResumeRequired || false,
    };
    if (filters.experience) {
      filterData['exp[min]'] = filters.experience[0] || 0;
      filterData['exp[max]'] = filters.experience[1] || 100;
    }
    if (filters.salary) {
      filterData['salary[min]'] = filters.salary[0] || 0;
      filterData['salary[max]'] = filters.salary[1] || 100;
    }
    if (filters.tag) {
      filterData['tag'] = filters.tag;
    }
    const query = queryString.stringify(
      { page, ...filterData },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );

    return axiosInstance({
      method: 'GET',
      url: `/api/v1/talent-pool?${query}`,
    });
  }

  static async updateCandidate(payload: {
    candidateId: number;
    name: string;
    mobile: string;
    email: string;
    tags?: string[];
  }) {
    return axiosInstance({
      method: 'PUT',
      url: '/api/v1/talent-pool/candidate',

      data: payload,
    });
  }

  static async fetchApplicantHistory(applicantId: number) {
    return await makeRequest(
      'GET',
      `/api/v1/talent-pool/applicant-history/${applicantId}`
    );
  }
}
