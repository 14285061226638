import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import JobApplicationAPI from '../../services/job-application.service';

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

const order = [
  'prospect',
  'screening',
  'client-review',
  'interview',
  'reject',
  'offer',
  'joined',
];

interface IState {
  loading: ILoading;
  error: string;
  data: [];
}

const fetchApplicantSnapshot = createAsyncThunk(
  'active-application-snapshot/snapshot',
  async (payload: any): Promise<any> => {
    const {
      workspaceId = '',
      stage = '',
      channelId = '',
      recruiterId = '',
    } = payload;
    return JobApplicationAPI.fetchActiveApplicationSnapshot({
      workspaceId,
      stage,
      channelId,
      recruiterId,
    });
  }
);

const initialState: IState = {
  loading: 'idle',
  error: '',
  data: [],
};

const activeApplicationsSnapshot = createSlice({
  name: 'active-application-snapshot',
  initialState,
  reducers: {
    resetList: (state) => {
      state.loading = 'idle';
      state.data = [];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchApplicantSnapshot.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchApplicantSnapshot.fulfilled, (state, action) => {
        const data: any = [...action.payload];
        data.sort(
          (a: any, b: any) =>
            order.indexOf(a.stageSlug) - order.indexOf(b.stageSlug)
        );
        state.loading = 'succeeded';
        state.data = data;
      })
      .addCase(fetchApplicantSnapshot.rejected, (state) => {
        state.loading = 'failed';
        state.error = 'Something went wrong!';
      }),
});

export default activeApplicationsSnapshot.reducer;

export { fetchApplicantSnapshot };

export const { resetList } = activeApplicationsSnapshot.actions;
