import { makeRequest } from '../../utils/axios.instance';

export default class ChannelAPI {
  static async fetchChannelMemberOptions(channelId: number): Promise<[]> {
    return makeRequest(
      'GET',
      `/api/v1/channel/${channelId}/members-suggestions`
    );
  }

  static async addMemberToChannel(
    channelId: number,
    members: { name: string; email: string; id: number }[]
  ): Promise<[]> {
    return makeRequest('POST', `/api/v1/channel/${channelId}/add-members`, {
      members,
    });
  }

  static async fetchChannelInfo(channelId: number) {
    return makeRequest('GET', `/api/v1/channel/${channelId}`);
  }
}
