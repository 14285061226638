import { makeRequest } from '../../utils/axios.instance';

export default class CandidateAPI {
  static async fetchRecruiterAction(candidateId: number) {
    return makeRequest('GET', `/api/v1/recruiter-event/${candidateId}`);
  }

  static async saveRecruiterAction(payload: any, candidateId: number) {
    return makeRequest(
      'PUT',
      `/api/v1/recruiter-event/${candidateId}`,
      payload
    );
  }

  static async shareProfileViaEmail(payload: any) {
    return makeRequest(
      'POST',
      '/api/v1/recruiter-event/share-profile-via-email',
      payload
    );
  }

  static async candidateTimeline(candidateId: number) {
    return makeRequest(
      'GET',
      `/api/v1/recruiter-activity-tracker/candidate-timeline/${candidateId}`
    );
  }

  static async tagsList() {
    return makeRequest('GET', '/api/v1/applicants/label');
  }

  static async createLabel(data: { name: string; color?: string }) {
    return makeRequest('POST', '/api/v1/applicants/label', data);
  }

  static async assignLabel(
    data: Array<{
      applicantId: number;
      labelId: number;
      isPrivate?: boolean;
    }>
  ) {
    return makeRequest('POST', '/api/v1/applicants/assign-label', data);
  }

  static async removeLabel(data: { applicantId: number; labelId: number }) {
    return makeRequest('DELETE', '/api/v1/applicants/assign-label', data);
  }

  static async assignedLabel(applicantId: number) {
    return makeRequest('GET', `/api/v1/applicants/assign-label/${applicantId}`);
  }
}
