import DashboardIcon from '@mui/icons-material/Dashboard';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import TodayIcon from '@mui/icons-material/Today';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

const dashboard = {
  id: 'dashboard',
  title: 'Workspace',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: 'recruiter-report',
      title: 'Reports',
      type: 'item',
      url: '/dashboard/recruiter-report',
      icon: BackupTableIcon,
      breadcrumbs: false,
      acl: ['invt_member'],
    },
    {
      id: 'workspace-comment',
      title: 'Comments',
      type: 'item',
      url: '/dashboard/comment-list',
      icon: ChatBubbleOutlineIcon,
      breadcrumbs: false,
      acl: ['invt_member'],
    },

    {
      id: 'workspace-calendar',
      title: 'Calendar',
      type: 'item',
      url: '/calendar',
      icon: CalendarMonthIcon,
      breadcrumbs: false,
    },
    {
      id: 'workspace-day',
      title: 'Day Planner',
      type: 'item',
      url: '/workspace/day-plan',
      icon: TodayIcon,
      breadcrumbs: false,
      acl: ['invt_member'],
    },
    {
      id: 'workspace-mail',
      title: 'Mails',
      type: 'item',
      url: '/workspace/mails',
      icon: EmailOutlinedIcon,
      breadcrumbs: false,
    },

    // {
    //   id: 'recent-activities',
    //   title: 'Activities',
    //   type: 'item',
    //   url: '/recent-activities',
    //   icon: FeedOutlinedIcon,
    //   breadcrumbs: false,
    // },
  ],
};

export default dashboard;
