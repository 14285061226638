import { useState, useEffect, useRef } from 'react';
import { Stack, Button, Box, Alert } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import _ from 'lodash';

import CandidateFeedback from './CandidateFeedback';
import { useAppSelector } from '../../hooks/redux';
import { getDNPStatus } from '../../redux/features/candidate/timeline.slice';
import FeedbackJSONForm from './FeebackJSONForm';

interface Props {
  applicationId: number;
  jobId: number;
  evaluationTemaplate?: any;
  onSubmitFeedback: any;
  feedbacks: Array<any>;
  onMarkDNP: () => void;
}

export default (props: Props) => {
  const [feedbackValues, setFeedbackValues] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const feedbackFormRef = useRef<{ handleFeedbackSubmit: () => void }>(null);
  const dnpStatus = useAppSelector(getDNPStatus);

  useEffect(() => {
    if (props.feedbacks.length) {
      const values: any = {};
      props.feedbacks[0].forEach((feedback: any) => {
        values[feedback.label] = feedback.value;
      });
      setFeedbackValues(values);
      setIsSubmitting(false);
    } else {
      setFeedbackValues({});
    }
  }, [props.feedbacks]);

  const handleChange = (e: any) => {
    setFeedbackValues({
      ...feedbackValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    feedbackFormRef.current?.handleFeedbackSubmit();
  };

  const handleFeedbackSubmit = (values: any) => {
    props.onSubmitFeedback(values);
    setFeedbackValues({});
    setIsSubmitting(true);
  };

  const template = props?.evaluationTemaplate?.template || [];

  const renderDNPButton = () => {
    if (dnpStatus == 'dnp') {
      return (
        <Alert severity="info">Candidate marked Call was unanswered!</Alert>
      );
    }

    if (dnpStatus == 'feedback-added') {
      return <></>;
    }

    return (
      <Button variant="outlined" onClick={props.onMarkDNP}>
        Call was unanswered
      </Button>
    );
  };

  return (
    <Box sx={{ mb: 2 }}>
      {template.length > 0 && (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          gap={2}
        >
          <CandidateFeedback
            template={template}
            feedbacks={feedbackValues}
            candidateId={props.applicationId}
          />
          <FeedbackJSONForm
            ref={feedbackFormRef}
            feedbackValues={feedbackValues}
            onsubmit={handleFeedbackSubmit}
          />
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gap={2}
          >
            {isSubmitting && (
              <Alert severity="info">Feedback submitted successfully!</Alert>
            )}
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSubmit}
            >
              {props.feedbacks.length ? 'Update' : 'Save'}
            </Button>

            {renderDNPButton()}
          </Stack>
        </Stack>
      )}
    </Box>
  );
};
