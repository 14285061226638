import { combineReducers } from 'redux';

import inbox from './inbox.slice';
import sent from './sent.slice';
import label from './label.slice';
import thread from './thread.slice';

const mailReducer = combineReducers({
  inbox,
  sent,
  label,
  thread,
});

export default mailReducer;
