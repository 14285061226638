import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  Link,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RefreshIcon from '@mui/icons-material/Refresh';
import { isMobile, isBrowser, isElectron } from 'react-device-detect';

import withAuth from '../../../utils/withAuth';
import { bgBlur } from '../../../utils/cssStyles';

const NAV_WIDTH = 0;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 50;

//@ts-ignore
const StyledRoot = styled(AppBar)(({ theme }: any) => ({
  ...bgBlur({ color: '#FFFFFF' }),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export const Header = (props: any) => {
  const navigate = useNavigate();
  const isHistoryAvailable = Boolean(navigate);
  const { isLoading, auth }: any = props;
  const isAuthenticate = isLoading ? null : auth.loggedIn();
  const location = useLocation();

  const handleBackward = () => {
    navigate(-1);
  };

  const handleForward = () => {
    navigate(1);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  if (isAuthenticate) {
    if (location.pathname != '/invitation') {
      if (localStorage.getItem('email-confirmed') == 'inactive') {
        navigate('/activate-account');
      } else {
        navigate('/channels');
      }
    }
  }

  return (
    <StyledRoot>
      <StyledToolbar>
        <Link href="/#/" underline="none">
          <Typography
            variant="h3"
            sx={{
              fontFamily: 'Montserrat',
              color: '#ff6451',
            }}
          >
            HireXL
          </Typography>
        </Link>
        {isElectron ? (
          <Box sx={{ flexGrow: 1 }} data-component-name="BaseHeader">
            <IconButton
              color="default"
              onClick={handleBackward}
              disabled={!isHistoryAvailable}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton color="default" onClick={handleForward}>
              <ArrowForwardIcon />
            </IconButton>
            <IconButton color="default" onClick={handleRefresh}>
              <RefreshIcon />
            </IconButton>
          </Box>
        ) : (
          <Box sx={{ flexGrow: 1 }}></Box>
        )}

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {isLoading ? (
            <CircularProgress size={25} />
          ) : (
            <>
              <Button size="small" href="#/login">
                Login
              </Button>
              <Button size="small" href="#/register">
                Signup
              </Button>
            </>
          )}
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
};

export default withAuth(Header);
