import queryString from 'query-string';

import { axiosInstance } from '../../utils/axios.instance';

export class MailerServiceAPI {
  static getTemplates(
    workspaceId: number | '',
    channelId: number,
    search?: string
  ) {
    const query = queryString.stringify(
      { search },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/mailer/template/${workspaceId}/${channelId}?${query}`,
    });
  }

  static addTemplate(data: {
    title: string;
    content: string;
    visibility?: 'public' | 'private' | undefined;
    workspaceId: number;
    channelId: number;
  }) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/mailer/template',
      data,
    });
  }
}
