import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import JobAPI from '../../services/job.service';
import { Dayjs } from 'dayjs';

type loadingType = 'idle' | 'pending' | 'succeeded' | 'failed';

interface IState {
  summary: {
    loading: loadingType;
    error: string;
    data: Record<string, string | number>;
  };
  application: {
    loading: loadingType;
    error: string;
    data: {
      total: number;
      list: Array<any>;
    };
  };
}

export const jobReport = createAsyncThunk(
  'jobReport/fetch-report',
  async (payload: {
    workspaceId: number;
    job: number;
    dateRange: { start: string; end: string };
  }): Promise<any> => {
    return JobAPI.fetchJobReport(
      payload.workspaceId,
      payload.job,
      payload.dateRange
    );
  }
);

export const jobReportApplication = createAsyncThunk(
  'jobReport/fetch-report-application',
  async (payload: {
    slug: string;
    job: number;
    dateRange: { start: string; end: string };
    page: number;
  }): Promise<any> => {
    return JobAPI.fetchJobReportApplication(
      payload.job,
      payload.slug,
      payload.dateRange,
      payload.page
    );
  }
);

const initialState: IState = {
  summary: {
    loading: 'idle',
    error: '',
    data: {},
  },
  application: {
    loading: 'idle',
    error: '',
    data: {
      total: 0,
      list: [],
    },
  },
};

const JobRepotSlice = createSlice({
  name: 'jobReport',
  initialState,
  reducers: {
    resetApplication: (state) => {
      state.application.loading = 'idle';
      state.application.data = {
        list: [],
        total: 0,
      };
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(jobReport.pending, (state) => {
        state.summary.loading = 'pending';
      })
      .addCase(jobReport.fulfilled, (state, action) => {
        state.summary.loading = 'succeeded';
        state.summary.data = action.payload;
      })
      .addCase(jobReport.rejected, (state) => {
        state.summary.loading = 'failed';
        state.summary.error = 'error';
      })
      .addCase(jobReportApplication.pending, (state) => {
        state.application.loading = 'pending';
      })
      .addCase(jobReportApplication.fulfilled, (state, action) => {
        state.application.loading = 'succeeded';
        state.application.data = {
          list: [...state.application.data.list, ...action.payload.list],
          total: action.payload.total,
        };
      })
      .addCase(jobReportApplication.rejected, (state) => {
        state.application.loading = 'failed';
        state.application.error = 'error';
      }),
});

export default JobRepotSlice.reducer;

export const { resetApplication } = JobRepotSlice.actions;
