import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import ReativeTime from 'dayjs/plugin/relativeTime';

import CollapsibleText from './CollapsibleText';

dayjs.extend(ReativeTime);

interface Props {
  event: any;
}

export default (props: Props) => {
  const { event } = props;
  return (
    <Box
      sx={{
        minWidth: 300,
        width: '100%',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexWrap="wrap"
        marginBottom="10px"
      >
        <Typography variant="body2" fontWeight="bold">
          {event.eventType.name}
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          flexWrap="wrap"
          gap={0.5}
        >
          <CollapsibleText
            text={event.metadata.description || ''}
            maxLength={80}
          />

          <Typography variant="caption">
            &bull; {dayjs(event.date).fromNow()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
