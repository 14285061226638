import queryString from 'query-string';
import { axiosInstance } from '../../utils/axios.instance';

export default class CommentAPI {
  static async addComment(data: any) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/comments',
      data,
    });
  }

  static async fetchComment(id: number) {
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/comments/${id}`,
    });
  }

  static async updateComment(commentId: number, data: any) {
    return axiosInstance({
      method: 'PUT',
      url: `/api/v1/comments/${commentId}`,
      data,
    });
  }

  static async addlikeReactionOnComment(data: { commentId: number }) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/comments/like-comment',
      data,
    });
  }

  static async fetchCommentSummary(start: string, end: string, page = 1) {
    const query = queryString.stringify(
      { 'date[start]': start, 'date[end]': end, page },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/comments/?${query}`,
    });
  }
}
