import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import WorkspaceUserSettingAPI from '../../services/workspace-settings.service';

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

interface IState {
  loading: ILoading;
  settings: any;
  error: string;
}

export const fetchUserSettings = createAsyncThunk(
  'user-settings/fetchUserSettings',
  async (payload: any) => {
    const { workspaceId = '' } = payload;
    return WorkspaceUserSettingAPI.getUserSetting(workspaceId);
  }
);

export const updateUserSettings = createAsyncThunk(
  'user-settings/updateUserSettings',
  async (payload: any) => {
    const { workspaceId = '', settings = {} } = payload;
    return WorkspaceUserSettingAPI.updateUserSetting(workspaceId, settings);
  }
);

const initialState: IState = {
  loading: 'idle',
  settings: {},
  error: '',
};

const userSettingsSlice = createSlice({
  name: 'user-settings',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addMatcher(
        isAnyOf(fetchUserSettings.pending, updateUserSettings.pending),
        (state) => {
          state.loading = 'pending';
          state.error = '';
        }
      )
      .addMatcher(
        isAnyOf(fetchUserSettings.fulfilled, updateUserSettings.fulfilled),
        (state, action) => {
          state.loading = 'succeeded';
          state.settings = action.payload;
          state.error = '';
        }
      )
      .addMatcher(
        isAnyOf(fetchUserSettings.rejected, updateUserSettings.rejected),
        (state) => {
          state.loading = 'failed';
          state.error = 'Something went wrong!';
        }
      ),
});

export default userSettingsSlice.reducer;
