import { axiosInstance, makeRequest } from '../../utils/axios.instance';
import queryString from 'query-string';

export class ReportAPI {
  static async columnFilterOptions(workspaceId: number, recruiterId: number) {
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/report-engine/filter-options?recruiter=${recruiterId}&workspaceId=${workspaceId}`,
    });
  }

  static async getWorkDoneReport(
    channelId: number,
    start: string,
    end: string,
    page: 1
  ) {
    const query = queryString.stringify(
      {
        start,
        end,
        page,
      },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/report-engine/work-done/${channelId}?${query}`,
    });
  }

  static async fetchReportByGroup(
    timeRange: [string, string],
    groupType: 'JOB' | 'RECRUITER' = 'JOB'
  ) {
    return makeRequest('POST', '/api/v2/dashboard/recruiter-report-by-group', {
      groupType,
      timeRange,
    });
  }

  static async reportVelocityApplications(
    type: string,
    category: 'distribution' | 'movement',
    id: number | string,
    start: string,
    end: string,
    page = 1,
    stage = ''
  ) {
    return makeRequest(
      'GET',
      `/api/v1/dashboard/report/application-list/${type}/${category}?id=${id}&date=${start}&date=${end}&page=${page}&stage=${stage}`
    );
  }
}
