import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
} from 'react';

interface CandidateModalContextType {
  isOpen: boolean;
  candidateId: number | null;
  openModal: (candidateId: number) => void;
  closeModal: () => void;
}

const CandidateContext = createContext<CandidateModalContextType | undefined>(
  undefined
);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [candidateId, setCandidateId] = useState<number | null>(null);

  const openModal = (candidateId: number) => {
    setCandidateId(candidateId);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCandidateId(null);
  };

  const contextValue = useMemo(
    () => ({ isOpen, candidateId, openModal, closeModal }),
    [isOpen, candidateId] // Dependencies
  );

  return (
    <CandidateContext.Provider value={contextValue}>
      {children}
    </CandidateContext.Provider>
  );
};

export const useCandidateModal = () => {
  const context = useContext(CandidateContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
