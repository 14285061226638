import { useEffect, useState, ChangeEvent, useRef } from 'react';
import {
  Grid,
  Skeleton,
  Box,
  CircularProgress,
  Alert,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import dayjs from 'dayjs';
import randomString from 'randomstring';
import EmailIcon from '@mui/icons-material/Email';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import {
  fetchEvalTemplate,
  saveFeedback,
  getFeedbacks,
  resetEvaluvationSubmit,
} from '../../redux/features/jobs/evaluation-template.slice';
import { getHiringWorkflow } from '../../redux/features/jobs/hiringWorkflow.slice';
import Header from './Header.react';
import Resume from './Resume.react';
import RecruiterActions from './RecruiterActions.react';
import RecruiterFeedbackForm from './RecruiterFeedbackForm.react';
import AddEventModal from '../event-calendar/AddEventModal.react';
import ApplicationTimeline from '../application-timeline';
import CommentView from '../comment-view';
import {
  addTaskLog,
  taskShareProfile,
} from '../../redux/services/hiring-workflow.service';
import CalendarAPI from '../../redux/services/calendar.service';
import { IEvent } from '../../types/calendar';
import { sendJobDescriptionEmail } from '../../redux/services/jobs';
import JobAPI from '../../redux/services/job.service';
import {
  fetchComments,
  addComment,
  updateComment,
  setComments,
} from '../../redux/features/comments/comment.slice';
import { candidateById } from '../../redux/features/applications/applicationSlice';
import { fetchCandidateTimeline } from '../../redux/features/candidate/timeline.slice';
import useLocalStorage from '../../hooks/use-localstorage';
import { ROOT_URL } from '../../utils/constants';
import CandidateInfoTabs from './CandidateInfoTabs';
import CandidateFeedback from './CandidateFeedback';
import MailList from '../mail-component/MailList';
import WorkspaceMailAPI from '../../redux/services/workspace-mail.service';
import { useCandidateDetails } from '../../hooks/use-candidate-details';
import CandidateTagsView from './CandidateTagsView';
import EmailComposer from '../email-composer';
import MailInboxAccordion from './MailInboxAccordion';

interface Props {
  candidateId?: number | null;
  isShowTimeline?: boolean;
}

export default (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [emailComposerOpen, setEmailComposerOpen] = useState(false);
  const recruiterActionsRef = useRef<{ handleDNPAction: () => void }>(null);
  const { isShowTimeline = true } = props;
  const jobApplicationId = params.get('candidateId') || props.candidateId;
  const {
    candidate,
    candidateTimeline,
    stageChangeList,
    feedbackValues,
    commentList,
    candidateInfoActiveTab,
    setCandidateInfoActiveTab,
    updateApplicant,
    setFeedbackValues,
  } = useCandidateDetails(Number(jobApplicationId));
  const [mails, setMails] = useState<any>([]);

  const { token = '' } = useAppSelector((state) => state.app);
  const { hiringWorkflow } = useAppSelector((state) => state.hiringWorkflow);
  const { evalTemplate, evaluations } = useAppSelector(
    (state: any) => state.evaluation
  );
  const [stageTimeline, setStageTimeline] = useState<any>([]);
  const [stakeholder, setStakeholder] = useState<any>([]);

  const [openEventModal, setOpenEventModal] = useState<boolean>(false);
  const [isTaskLogUpdating, setTaskLogUpdating] = useState<boolean>(false);
  const [clientFeedbackActions, setCientFeedbackShared] = useState<any>(null);
  const [leadFeedbackActions, setTLFeedbackShared] = useState<any>(null);
  const [user] = useLocalStorage('user', null);

  const fetchInbox = (applicant: number, jobId?: number) => {
    WorkspaceMailAPI.getApplicantCommunication(applicant, jobId)
      .then((data) => {
        setMails(data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    if (candidate.details.jobId && candidate.details.applicantId) {
      fetchInbox(
        Number(candidate.details.applicantId),
        Number(candidate.details.jobId)
      );
    }
  }, [candidate?.details?.id]);

  useEffect(() => {
    if (jobApplicationId) {
      //TODO: Rename Action to candidatByJobApplicationId
      dispatch(candidateById(Number(jobApplicationId)));
      dispatch(fetchCandidateTimeline(Number(jobApplicationId)));
    }
  }, [jobApplicationId]);

  useEffect(() => {
    return () => {
      dispatch(resetEvaluvationSubmit());
    };
  }, []);

  const getJobStakeholder = (jobId: number) => {
    JobAPI.stakeholder(jobId)
      .then((response) => {
        setStakeholder(response);
      })
      .catch((error) => {
        setStakeholder([]);
      });
  };

  useEffect(() => {
    if (candidate.details.jobId) {
      dispatch(getHiringWorkflow(Number(candidate.details.jobId)));
      dispatch(fetchEvalTemplate(Number(candidate.details.jobId)));
      getJobStakeholder(Number(candidate.details.jobId));
    }
  }, [candidate.details.jobId]);

  useEffect(() => {
    dispatch(getFeedbacks(Number(jobApplicationId)));
    dispatch(fetchComments(Number(jobApplicationId)));
  }, [jobApplicationId, user]);

  useEffect(() => {
    if (
      hiringWorkflow.data.length &&
      stageChangeList.length &&
      candidate?.details?.hiringStatus?.stageId
    ) {
      const pipelineStages: any = hiringWorkflow.data.map(({ stage }: any) => {
        let status = 'Upcoming';
        if (stageChangeList.includes(stage.slug)) {
          status = 'Done';
        }
        if (stage.id == candidate?.details?.hiringStatus?.stageId) {
          status = 'Active';
        }
        return {
          diaplayName: stage.name,
          status,
        };
      });
      setStageTimeline(pipelineStages);
    }
  }, [
    hiringWorkflow.data,
    candidate?.details?.hiringStatus?.stageId,
    stageChangeList,
  ]);

  // Remove SSE Comment fetching to avoid overhead
  // useEffect(() => {
  //   if (candidate?.details?.id && user?.id) {
  //     const eventSource = new EventSource(
  //       `${ROOT_URL}/api/v1/comments/sse/${jobApplicationId}?userId=${user.id}`
  //     );

  //     eventSource.onmessage = (event) => {
  //       const newData = JSON.parse(event.data);
  //       dispatch(setComments(newData));
  //     };

  //     return () => {
  //       eventSource.close();
  //     };
  //   }
  // }, [dispatch, candidate?.details?.id, user?.id]);

  const {
    name = 'N/A',
    email = 'N/A',
    mobile = 'N/A',
    job,
    s3Url = '',
    remark,
    createdAt,
    labelMapping = [],
  } = candidate.details;

  const handleTaskSubmit = (data: any) => {
    const { id, jobId }: any = candidate.details;
    setTaskLogUpdating(true);
    addTaskLog({ ...data, jobId, applicationId: id })
      .then((response) => {
        setTaskLogUpdating(false);
      })
      .catch((err) => {
        console.log(err);
        setTaskLogUpdating(false);
      });
  };

  const handleTaskType = (taskType: string, assignedToId?: Array<number>) => {
    const { id, jobId }: any = candidate.details;
    setTaskLogUpdating(true);
    if (taskType == 'SHARE_JD') {
      sendJobDescriptionEmail({
        recipients: [email],
        subject: 'Job Description',
        message: '<p>Hi</p> <p>Please find job description</p>',
        jobId: jobId,
      })
        .then((res) => {
          setTaskLogUpdating(false);
        })
        .catch((err) => {
          setTaskLogUpdating(false);
        });
    } else {
      taskShareProfile({ taskType, jobId, applicationId: id, assignedToId })
        .then((response) => {
          setTaskLogUpdating(false);
        })
        .catch((err) => {
          console.log(err);
          setTaskLogUpdating(false);
        });
    }
  };

  const handleCloseEventModal = () => {
    setOpenEventModal(false);
  };

  const handleAddEvent = (event: any) => {
    const eventRequest: IEvent = {
      summary: event.title,
      description: event.description,
      start: {
        dateTime: dayjs(
          `${event.date} ${event.startTime}`,
          'YYYY-MM-DD hh:mm:A'
        ).toISOString(),
        timeZone: 'utc',
      },
      end: {
        dateTime: dayjs(
          `${event.date} ${event.startTime}`,
          'YYYY-MM-DD hh:mm:A'
        )
          .add(event.duration, 'minutes')
          .toISOString(),
        timeZone: 'utc',
      },
      attendees: event.attendees.map((user: any) => ({ email: user })),
    };
    if (event.googleConfrence) {
      eventRequest['conferenceData'] = {
        createRequest: {
          conferenceSolutionKey: {
            type: 'hangoutsMeet',
          },
          requestId: randomString.generate(10),
        },
      };
    }
    CalendarAPI.addEvent(eventRequest)
      .then((response) => {
        setOpenEventModal(false);
      })
      .catch((error) => {
        console.log('err', error);
      });
  };

  const handleOpenEventModal = () => setOpenEventModal(true);

  const onSubmitFeedback = (values: any) => {
    const { id, jobId }: any = candidate.details;
    const feedback: any = [];
    Object.keys(values).map((key: any) => {
      const data = {
        label: key,
        value: values[key],
        jobId,
        applicationId: id,
      };

      feedback.push(data);
    });

    dispatch(saveFeedback(feedback));
  };

  const handleCommentSubmit = async (
    mode: string,
    inputText: string,
    commentId?: number | null,
    commentParentId?: number | null
  ) => {
    const payload = {
      postId: Number(jobApplicationId),
      commentText: inputText,
      parentCommentID: commentParentId,
    };
    if (mode === 'EDIT') {
      await dispatch(updateComment({ commentId, data: payload }));
    } else {
      dispatch(addComment(payload));
    }
  };

  const handleOpenFeedbackForm = () => {
    setCandidateInfoActiveTab('1');
  };

  const handleUpdateCV = (event: ChangeEvent<HTMLInputElement> | undefined) => {
    //@ts-ignore
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('id', `${jobApplicationId}`);
      formData.append('file', file);
      updateApplicant(formData);
    }
  };

  const handleUpdateCandidateInfo = (data: any) => {
    return new Promise((resolve, reject) => {
      updateApplicant({ ...data, id: jobApplicationId })
        .then((result) => {
          if (result.meta.requestStatus == 'rejected') {
            const message = result.payload || 'Something went wrong!';
            reject(message);
          }
          resolve('');
        })
        .catch((error) => {
          reject('Something went wrong!');
        });
    });
  };

  useEffect(() => {
    if (evaluations.data.length) {
      const values: any = {};
      evaluations.data[0].forEach((feedback: any) => {
        values[feedback.label] = feedback.value;
      });
      setFeedbackValues(values);
    } else {
      setFeedbackValues({});
    }
  }, [evaluations.data]);

  const handleActiveTabChange = (activeTable: string) => {
    setCandidateInfoActiveTab(activeTable);
  };

  const handleDNPAction = () => {
    recruiterActionsRef.current?.handleDNPAction();
  };

  const handleRowClick = (row: any) => {
    navigate(`/workspace/mails/${row.threadId}`);
  };

  const handleEmailSubmit = (values: any) => {
    console.log('values', values);
  };

  return (
    <Grid
      container
      p={2}
      mt={2}
      spacing={2}
      data-component-name={`candidateDetails-${jobApplicationId}`}
    >
      {emailComposerOpen && (
        <EmailComposer
          recipientList={[email]}
          closeBtn
          handleClose={() => setEmailComposerOpen(false)}
          handleEmailSubmit={handleEmailSubmit}
          loading
          attachment={[]}
          initialContent=""
        />
      )}
      <AddEventModal
        open={openEventModal}
        handleClose={handleCloseEventModal}
        onAddEvent={handleAddEvent}
        event={{
          extendedProps: {
            raw: {
              attendees: [{ email }],
            },
          },
        }}
      />
      <Grid item xs={12} md={isShowTimeline ? 8 : 12}>
        {candidate.loading == 'succeeded' ? (
          <>
            <Header
              name={name}
              email={email}
              mobile={mobile}
              job={job}
              remark={remark}
              createdAt={createdAt}
              stageProgress={stageTimeline}
              onUpdate={handleUpdateCandidateInfo}
            />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {candidate?.details?.applicantId && (
                <CandidateTagsView
                  applicantId={candidate.details.applicantId}
                />
              )}
              <Tooltip title="Send Email">
                <IconButton
                  color="primary"
                  onClick={() => setEmailComposerOpen(true)}
                >
                  <EmailIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        ) : (
          <CircularProgress size={24} />
        )}

        <CandidateInfoTabs
          tabList={[
            {
              label: 'Resume',
              tabPanel: (
                <>
                  {!evaluations?.data.length ? (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                      No feedback has been provided for this candidate.
                    </Alert>
                  ) : (
                    <CandidateFeedback
                      template={evalTemplate.data.template}
                      feedbacks={feedbackValues}
                      candidateId={candidate.details.id || ''}
                    />
                  )}

                  <Resume
                    cvUrl={s3Url}
                    token={token}
                    onChangeCV={handleUpdateCV}
                    loading={candidate.loading == 'pending'}
                  />
                </>
              ),
            },
            {
              label: 'Feedback',
              tabPanel: (
                <>
                  {evaluations.loading === 'pending' && (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={60}
                      sx={{ marginY: 2 }}
                    />
                  )}
                  {!evalTemplate.data ? (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                      No feedback template found. Please edit job and add
                      feedback form template
                    </Alert>
                  ) : (
                    <RecruiterFeedbackForm
                      applicationId={Number(candidate.details.id)}
                      jobId={Number(candidate.details.jobId)}
                      onSubmitFeedback={onSubmitFeedback}
                      evaluationTemaplate={evalTemplate.data}
                      feedbacks={evaluations.data}
                      onMarkDNP={handleDNPAction}
                    />
                  )}
                </>
              ),
            },
            {
              label: 'Timeline',
              tabPanel: (
                <ApplicationTimeline timeline={candidateTimeline.data || []} />
              ),
            },
            {
              label: 'Communication',
              tabPanel:
                mails.length > 0 ? (
                  <MailInboxAccordion mails={mails} />
                ) : (
                  <Alert severity="info">
                    No email communication with this candidates from HireXL
                  </Alert>
                ),
            },
          ]}
          activeTab={candidateInfoActiveTab}
          onChangeActiveTab={handleActiveTabChange}
        />

        <Box py={1} />

        {user ? (
          <CommentView
            comments={commentList.data}
            user={user}
            stakeholder={stakeholder}
            onSubmit={handleCommentSubmit}
            isSubmitting={commentList.loading == 'pending'}
          />
        ) : (
          <></>
        )}
      </Grid>

      <Grid item xs={12} md={4}>
        <RecruiterActions
          ref={recruiterActionsRef}
          onTaskSubmit={handleTaskSubmit}
          onTaskTypeSubmit={handleTaskType}
          loading={isTaskLogUpdating}
          applicationId={Number(candidate.details.id)}
          jobId={Number(candidate.details.jobId)}
          taskTLFeedback={leadFeedbackActions}
          taskCLFeedback={clientFeedbackActions}
          onOpenEventModal={handleOpenEventModal}
          stakeholders={stakeholder}
          hiringWorkflow={
            _.isArray(hiringWorkflow?.data) ? hiringWorkflow?.data : []
          }
          openFeedbackForm={handleOpenFeedbackForm}
        />
        <ApplicationTimeline timeline={candidateTimeline.data || []} />
      </Grid>
    </Grid>
  );
};
