import { lazy } from 'react';

// core components
import DashboardLayout from '../components/layout/dashboard-layout';
import DashboardPage from '../pages/Dashboard.page';
import SuspenseView from '../components/SuspenseView.react';

//TODO: view component should be moved to view folder
import AddCandidate from '../components/AddCandidate';
import JobApplicants from '../components/JobApplicants';
import CandidateDetailsView from '../components/candidate-details';
import EventCalendar from '../components/event-calendar';
// End of TODO

const TalentPoolPage = lazy(() => import('../pages/talent-pool'));
const Reports = lazy(() => import('../pages/reports/Report.page'));
const JobPostingPage = lazy(() => import('../pages/JobPosting.page'));
const EditJobPage = lazy(() => import('../pages/EditJob.page'));
const NotificationPage = lazy(() => import('../pages/Notification.page.react'));
const AddJob = lazy(() => import('../pages/AddJob.page'));
const RecruiterInboxPage = lazy(() => import('../pages/RecruiterInbox.page'));
const WorkspaceJobs = lazy(() => import('../pages/WorkspaceJobs.page'));
const Candidates = lazy(() => import('../pages/Candidates.page'));
const RecentActivity = lazy(() => import('../pages/RecentActivity.page'));
const ChannelsPage = lazy(() => import('../pages/channels'));
const DayPlanner = lazy(() => import('../pages/day-planner/DayPlanner'));
const ActiveApplicant = lazy(() => import('../pages/active-applicants'));

// Admin accessiable page(s)
const RecruiterDayTODO = lazy(() => import('../pages/recruiter/DayToDo'));
const ReportDetailView = lazy(
  () => import('../pages/reports/ReportDetailView.page')
);
const RecruiterProfile = lazy(() => import('../pages/recruiter/Profile'));
const MailPage = lazy(() => import('../pages/Mail'));
const MailInboxPage = lazy(() => import('../pages/MailInbox'));
const MailISentPage = lazy(() => import('../pages/MailISent'));
const MailLabelPage = lazy(() => import('../pages/MailLabel'));
const MailTrailPage = lazy(() => import('../pages/MailTrail'));
const ChannelInfoPage = lazy(() => import('../pages/channel-info.page'));
const ReportApplicationList = lazy(
  () => import('../pages/reports/ReportApplicationList')
);
const CommentListPage = lazy(() => import('../pages/comment-list.page'));

const WorkspaceRoutes = {
  path: '/',
  element: <DashboardLayout />,
  children: [
    {
      path: 'dashboard',
      element: <DashboardPage />,
    },
    {
      path: 'workspace',
      element: <DashboardPage />,
    },
    {
      path: 'dashboard/recruiter-report',
      element: (
        <SuspenseView>
          <Reports />
        </SuspenseView>
      ),
    },
    {
      path: 'dashboard/recruiter-report/application-list',
      element: (
        <SuspenseView>
          <ReportApplicationList />
        </SuspenseView>
      ),
    },
    {
      path: 'dashboard/recruiter-report/day-to-do/:recruiterId',
      element: (
        <SuspenseView>
          <RecruiterDayTODO />
        </SuspenseView>
      ),
    },
    {
      path: 'dashboard/recruiter-report/details/:recruiterId',
      element: (
        <SuspenseView>
          <ReportDetailView />
        </SuspenseView>
      ),
    },
    {
      path: 'dashboard/recruiter-report/profile/:recruiterId',
      element: (
        <SuspenseView>
          <RecruiterProfile />
        </SuspenseView>
      ),
    },
    {
      path: 'dashboard/comment-list',
      element: (
        <SuspenseView>
          <CommentListPage />
        </SuspenseView>
      ),
    },
    {
      path: 'workspace/jobs',
      element: (
        <SuspenseView>
          <WorkspaceJobs />
        </SuspenseView>
      ),
    },
    {
      path: 'workspace/day-plan',
      element: (
        <SuspenseView>
          <DayPlanner />
        </SuspenseView>
      ),
    },
    {
      path: 'workspace/mails',
      element: (
        <SuspenseView>
          <MailPage />
        </SuspenseView>
      ),
      children: [
        {
          index: true,
          element: (
            <SuspenseView>
              <MailInboxPage mailType="inbox" />
            </SuspenseView>
          ),
        },
        {
          path: 'inbox',
          element: (
            <SuspenseView>
              <MailInboxPage mailType="inbox" />
            </SuspenseView>
          ),
        },
        {
          path: 'sent',
          element: (
            <SuspenseView>
              <MailISentPage mailType="sent" />
            </SuspenseView>
          ),
        },
        {
          path: 'label/:labelId',
          element: (
            <SuspenseView>
              <MailLabelPage />
            </SuspenseView>
          ),
        },
      ],
    },
    {
      path: 'workspace/mails/:thread',
      element: (
        <SuspenseView>
          <MailTrailPage />
        </SuspenseView>
      ),
    },
    {
      path: 'channels',
      element: (
        <SuspenseView>
          <ChannelsPage />
        </SuspenseView>
      ),
    },
    {
      path: 'channels/:id',
      element: (
        <SuspenseView>
          <ChannelInfoPage />
        </SuspenseView>
      ),
    },
    {
      path: 'channels/postings',
      element: (
        <SuspenseView>
          <RecruiterInboxPage />
        </SuspenseView>
      ),
    },
    {
      path: 'channels/postings/:id',
      element: (
        <SuspenseView>
          <JobPostingPage />
        </SuspenseView>
      ),
    },
    {
      path: 'channels/postings/create',
      element: (
        <SuspenseView>
          <AddJob />
        </SuspenseView>
      ),
    },
    {
      path: 'channels/postings/job',
      element: (
        <SuspenseView>
          <EditJobPage />
        </SuspenseView>
      ),
    },
    {
      path: 'channels/postings/add-candidate',
      element: <AddCandidate />,
    },
    {
      path: 'channels/applicants',
      element: (
        <SuspenseView>
          <Candidates />
        </SuspenseView>
      ),
    },
    {
      path: 'applicants/:jhash',
      element: <JobApplicants />,
    },
    {
      path: 'applicant',
      element: <CandidateDetailsView />,
    },
    {
      path: 'calendar',
      element: <EventCalendar />,
    },
    {
      path: 'notifications',
      element: (
        <SuspenseView>
          <NotificationPage />
        </SuspenseView>
      ),
    },
    {
      path: 'candidates/talent-pool',
      element: (
        <SuspenseView>
          <TalentPoolPage />
        </SuspenseView>
      ),
    },
    {
      path: 'candidates/active-applicant',
      element: (
        <SuspenseView>
          <ActiveApplicant />
        </SuspenseView>
      ),
    },
    {
      path: 'recent-activities',
      element: (
        <SuspenseView>
          <RecentActivity />
        </SuspenseView>
      ),
    },
  ],
};

export default WorkspaceRoutes;
