import { combineReducers } from 'redux';
import applicantReducer from './applicant.slice';
import applicantHistory from './applicant-history';

const talentPoolReducer = combineReducers({
  applicant: applicantReducer,
  applicantHistory,
});

export default talentPoolReducer;
