import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';

function a11yProps(index: number) {
  return {
    id: `candidate-info-tab-${index}`,
    'aria-controls': `candidate-info-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  keepMounted?: boolean;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, keepMounted = true, ...other } = props;
  return (
    <div
      role="tabpanel"
      style={{ display: value === index ? 'block' : 'none' }}
      id={`candidate-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ py: 2 }}>{children}</Box>
    </div>
  );
}

interface Props {
  tabList: Array<{
    label: string;
    tabPanel: React.ReactNode;
    keepMounted?: boolean;
  }>;
  activeTab: string;
  onChangeActiveTab: (tabValue: string) => void;
}

const CandidateInfoTabs = ({
  tabList = [],
  activeTab,
  onChangeActiveTab,
}: Props) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChangeActiveTab(`${newValue}`);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab ? Number(activeTab) : 0}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabList.map((tab) => (
            <Tab
              key={`tab-label-${tab.label}`}
              label={tab.label}
              {...a11yProps(0)}
            />
          ))}
        </Tabs>
      </Box>
      {tabList.map((tab, index) => (
        <CustomTabPanel
          key={`tabpanel-${tab.label}`}
          keepMounted={tab.keepMounted}
          index={index}
          value={Number(activeTab)}
        >
          {tab.tabPanel}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default CandidateInfoTabs;
