import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Box, List, ListItemButton, ListItemText, Paper } from '@mui/material';

export const MentionList: React.FC = forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];
    if (item) {
      props.command(item);
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: any) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));
  console.log('props.items', props.items);
  return (
    <Box component={Paper} elevation={1} display="flex" flexDirection="column">
      <List>
        {props.items.length
          ? props.items.map((item: any, index: number) => (
              <ListItemButton
                key={`mention-options-${index}`}
                onClick={() => selectItem(index)}
                selected={index === selectedIndex}
              >
                <ListItemText primary={item.label} />
              </ListItemButton>
            ))
          : ''}
      </List>
    </Box>
  );
});
