import { makeRequest } from '../../utils/axios.instance';

export default class WorkspaceSettingsAPI {
  static async getUserSetting(workspaceId: number) {
    return await makeRequest(
      'GET',
      `/api/v1/workspace-settings/user-setting/${workspaceId}`
    );
  }

  static async updateUserSetting(workspaceId: number, data: any) {
    return await makeRequest(
      'POST',
      `/api/v1/workspace-settings/user-setting/${workspaceId}`,
      data
    );
  }
}
