import { combineReducers } from 'redux';

import activeApplicant from './activeApplicationSlice';
import activeApplicantSnapshot from './activeApplicantSnapshotSlice';

const activeApplication = combineReducers({
  applications: activeApplicant,
  snapshots: activeApplicantSnapshot,
});

const applicationReducer = combineReducers({
  activeApplication,
});

export default applicationReducer;
