import React, { useEffect, useMemo, useState } from 'react';
import { Box, Paper, Chip, Typography } from '@mui/material';

import LabelPopover from '../mail-component/LabelPopover';
import AddLabelModel from '../mail-component/AddLabelModel';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  fetchTagList,
  createLabel,
  assignLabel,
  assignedLabel,
} from '../../redux/features/candidate/candidate-tags.slice';

interface Props {
  applicantId: number;
}

const CandidateTagsView: React.FC<Props> = ({ applicantId }) => {
  const dispatch = useAppDispatch();
  const [openLabelModel, setOpenLabelModel] = useState<boolean>(false);
  const { data = [] } = useAppSelector(
    (state) => state.candidate.candidateTags.labelOptions
  );
  const { loading: labelLoading = 'idle', data: labels = [] } = useAppSelector(
    (state) => state.candidate.candidateTags.labels
  );

  useEffect(() => {
    dispatch(fetchTagList());
    dispatch(assignedLabel({ applicantId: Number(applicantId) }));
  }, [applicantId]);

  const assignedLabels = useMemo(() => {
    return labels
      .map((label) => label?.id)
      .filter(Boolean)
      .map((id) => ({ labelId: id }));
  }, [labels]);

  const handleAddLabel = (values: any) => {
    if (values.length) {
      const payload = values.map((value: any) => ({
        applicantId,
        labelId: Number(value),
      }));
      dispatch(assignLabel(payload))
        .then((result) => {
          dispatch(assignedLabel({ applicantId: Number(applicantId) }));
          setOpenLabelModel(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleLabelSubmit = (data: any) => {
    if (data.label) {
      dispatch(createLabel({ name: data.label }));
    }
  };

  return (
    <>
      <AddLabelModel
        open={openLabelModel}
        onClose={() => setOpenLabelModel(false)}
        onSubmit={handleLabelSubmit}
        label=""
      />
      <Box
        component={Paper}
        width="100%"
        py={2}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={1}
        alignItems="center"
      >
        <Typography variant="subtitle1">Labels</Typography>
        {labels.map((label) => (
          <Chip key={`tags-${label.id}`} size="small" label={label.name} />
        ))}
        <LabelPopover
          labels={data}
          activeLabel={assignedLabels}
          onSubmit={handleAddLabel}
          onOpenLabelModel={() => setOpenLabelModel(true)}
        />
      </Box>
    </>
  );
};

export default CandidateTagsView;
