import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Formik } from 'formik';
import {
  Grid,
  Box,
  TextField,
  InputAdornment,
  Alert,
  FormHelperText,
  CircularProgress,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Stack,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';

import InvitePermissions from './InviteFormPermissions.react';
import { axiosInstance } from '../../utils/axios.instance';
import { ROOT_URL } from '../../utils/constants';
import JobAPI from '../../redux/services/job.service';
import AddJobPopover from './AddJobPopover';

const defaultState = {
  message: '',
  status: true,
  rolesLoading: true,
  roles: [],
  selectedRoleScopes: {},
};

interface Option {
  id: number;
  title: string;
}

const InviteForm = (props: any) => {
  const [formState, setFormState] = useState(defaultState);
  const [jobList, setJobList] = useState<Option[]>([
    { id: 1111111, title: 'All' },
  ]);

  const { clients, workspace, inviteWorkspaceUser = true } = props;

  useEffect(() => {
    axiosInstance
      .get(`${ROOT_URL}/api/v1/job/roles`)
      .then(({ data }) => {
        setFormState((prev) => {
          return {
            ...prev,
            rolesLoading: false,
            roles: data,
          };
        });
      })
      .catch((err) => {
        setFormState((prev) => {
          return {
            ...prev,
            message:
              'Unknown error has occurred, please contact support or try logging out and then login!',
            rolesLoading: false,
            status: false,
          };
        });
      });
  }, []);

  const onInvitingPerson = (
    values: any,
    setSubmitting: any,
    resetForm: any
  ) => {
    const selectedRole = values.permissions;
    const scopes: any = [];
    Object.keys(values.scope).forEach((key) => {
      if (values.scope[key]) {
        scopes.push(key);
      }
    });
    const permissions: any = {
      role: selectedRole,
      scopes,
    };
    axiosInstance({
      method: 'POST',
      url: 'api/v1/user-invitation/send-invite',
      data: {
        name: `${values.firstName} ${values.lastName}`,
        email: values.email,
        permissions,
        channelId: values.client,
        workspaceId: workspace.id,
        assignJobs: values.assignJobs || null,
      },
      headers: {
        'hxl-workspace': workspace.id,
      },
    })
      .then(({ data }) => {
        setFormState((prev) => {
          return {
            ...prev,
            message: data.message,
            status: true,
          };
        });
        hideAlert(2000);
        setSubmitting(false);
        setJobList([]);
        resetForm();
      })
      .catch((err) => {
        console.log('err', err?.response?.data?.error);
        setFormState((prev) => {
          return {
            ...prev,
            status: false,
            message:
              err?.response?.data?.error ||
              'An Unknown error has occurred, please try again later',
          };
        });
        hideAlert(2000);
        setSubmitting(false);
      });
  };

  const hideAlert = (time: number) => {
    setTimeout(() => {
      setFormState((prev) => {
        return {
          ...prev,
          message: '',
        };
      });
    }, time);
  };

  const fetchChannelJobs = (value: string) => {
    JobAPI.fetchWorkspaceChannelJobs(Number(workspace.id), Number(value))
      .then((data: any) => {
        setJobList(data);
      })
      .catch((error) => {
        setJobList([]);
      });
  };

  return (
    <Box>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          permissions: 'recruitment-manager',
          client: '',
          scope: {},
          assignJobs: '',
        }}
        validate={(values: any) => {
          let errors: any = {};
          // assign error properties based on the values
          if (!values.firstName) {
            errors.firstName = 'First name is required';
          } else if (values.firstName.length < 3) {
            errors.firstName = 'First name should be at least of 3 words';
          }
          // Now, validating the email
          if (!values.email) {
            errors.email = 'Email is required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = 'Email is invalid';
          } else if (!values.permissions) {
            errors.permissions = 'Please select a Role';
          } else if (values.permissions) {
            const scopesSelected = Object.values(values.scope).some(
              (val) => val === true
            );
            if (!scopesSelected) {
              errors.roles = `Please select permissions of the ${values.permissions}`;
            }
          }
          console.log('values', values);
          return errors;
        }}
        onSubmit={(
          values,
          { setSubmitting, setErrors, setStatus, resetForm }
        ) => {
          let jobs = null;
          if (values.assignJobs == '1111111') {
            jobs = [{ id: 1111111, title: 'All' }];
          }
          onInvitingPerson(
            { ...values, assignJobs: jobs },
            setSubmitting,
            resetForm
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }: any) => (
          <Box component="form" onSubmit={handleSubmit} py={2}>
            <Stack direction="row" gap={1}>
              <TextField
                fullWidth
                margin="normal"
                placeholder="First name"
                name="firstName"
                type="text"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                error={touched.firstName && errors && errors.firstName}
                helperText={touched.firstName && errors && errors.firstName}
              />
              <TextField
                fullWidth
                margin="normal"
                placeholder="Last Name"
                name="lastName"
                type="text"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <TextField
              fullWidth
              margin="normal"
              placeholder="Email"
              name="email"
              type="email"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon />
                  </InputAdornment>
                ),
              }}
              error={touched.email && errors && errors.email}
              helperText={touched.email && errors && errors.email}
            />
            {!inviteWorkspaceUser && (
              <FormControl fullWidth size="small" margin="normal">
                <InputLabel id="invite-client-select-label">
                  Select Channel
                </InputLabel>
                <Select
                  labelId="invite-client-select-label"
                  id="invite-client-select"
                  label="Select Channel"
                  name="client"
                  value={values.client}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                      setFieldValue('assignJobs', '');
                      fetchChannelJobs(value);
                    }
                    handleChange(e);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  {clients.map((holder: any) => (
                    <MenuItem value={holder.id} key={`client-${holder.id}`}>
                      {holder.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {!inviteWorkspaceUser && (
              <FormControl sx={{ mt: 1 }}>
                <FormLabel id="assign-jobs-radio-buttons-group-label">
                  Assign Jobs(optional)
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="assign-jobs-radio-buttons-group-label"
                  name="assign-jobs-buttons-group"
                  value={values.assignJobs}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(
                      'assignJobs',
                      (event.target as HTMLInputElement).value
                    );
                  }}
                >
                  <FormControlLabel
                    value="1111111"
                    control={<Radio />}
                    label="All"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="I'll manually assign jobs from channel"
                  />
                </RadioGroup>
              </FormControl>
            )}
            <Box>
              {formState.rolesLoading ? (
                <CircularProgress size={20} />
              ) : (
                <InvitePermissions
                  values={values}
                  roles={formState.roles}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              )}
              <FormHelperText error>
                {errors && errors.permissions}
              </FormHelperText>
              <FormHelperText error>{errors && errors.roles}</FormHelperText>
            </Box>
            <Grid item xs={12}>
              {!formState.rolesLoading && (
                <LoadingButton
                  loading={isSubmitting}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Send Invite
                </LoadingButton>
              )}
            </Grid>
          </Box>
        )}
      </Formik>
      {formState.message && (
        <Alert severity={formState.status ? 'success' : 'error'}>
          {formState.message}
        </Alert>
      )}
    </Box>
  );
};

export default InviteForm;
