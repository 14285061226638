import React from 'react';
import { Modal, Slide, Box, Paper, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface SlideModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactElement;
  minWidth?: string;
}

const SlideModal: React.FC<SlideModalProps> = ({
  open,
  onClose,
  children,
  minWidth = '40vw',
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      sx={{ overflowY: 'scroll', height: '100%', outline: 'none' }}
    >
      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <Box
          component={Paper}
          width="100%"
          maxWidth={minWidth}
          height="100%"
          p={2}
          borderRadius={0}
          sx={{
            position: 'fixed',
            right: 0,
            overflow: 'scroll',
            outline: 'none',
          }}
        >
          <Box position="absolute" right={0} top={15}>
            <Tooltip title="Close">
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {children}
        </Box>
      </Slide>
    </Modal>
  );
};

export default SlideModal;
