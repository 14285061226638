import queryString from 'query-string';
import { makeRequest } from '../../utils/axios.instance';

export default class WorkspaceMailAPI {
  static async getInbox(page: number) {
    return makeRequest('GET', `/api/v1/recruiter-inbox/inbox?page=${page}`);
  }

  static async getThreadMails(thread: number) {
    return makeRequest('GET', `/api/v1/recruiter-inbox/thread/${thread}`);
  }

  static async getApplicantCommunication(applicantId: number, job?: number) {
    const query = queryString.stringify(
      {
        job,
      },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    return makeRequest(
      'GET',
      `/api/v1/recruiter-inbox/${applicantId}/inbox?${query}`
    );
  }

  static async sendEmail(payload: any) {
    return makeRequest('POST', '/api/v1/recruiter-inbox/send', payload);
  }

  static async getSentEmail(page: number) {
    return makeRequest('GET', `/api/v1/recruiter-inbox/sent?page=${page}`);
  }

  static async getLabelEmail(labelId: number, page: number) {
    return makeRequest(
      'GET',
      `/api/v1/recruiter-inbox/${labelId}/emails?page=${page}`
    );
  }

  static async getLabels() {
    return makeRequest('GET', '/api/v1/recruiter-inbox/label');
  }

  static async createLabel(data: { name: string; color?: string }) {
    return makeRequest('POST', '/api/v1/recruiter-inbox/label', data);
  }

  static async assignLabelsToMailThread(
    threadId: number,
    data: { labelIds: number[] }
  ) {
    return makeRequest(
      'POST',
      `/api/v1/recruiter-inbox/${threadId}/labels`,
      data
    );
  }

  static async removeLabelFromMailThread(threadId: number, labelIds: number) {
    return makeRequest(
      'DELETE',
      `/api/v1/recruiter-inbox/${threadId}/labels/${labelIds}`
    );
  }

  static async emailCategory() {
    return makeRequest('GET', '/api/v1/recruiter-inbox/inbox-category');
  }

  static async getCategoryInbox(categoryId: number, page: number) {
    return makeRequest(
      'GET',
      `/api/v1/recruiter-inbox/inbox/${categoryId}?page=${page}`
    );
  }
}
