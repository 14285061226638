import { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  Paper,
} from '@mui/material';

import Comment from './Comment.react';
import InputField from './InputField';

interface Props {
  comments: Array<any>;
  user: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  stakeholder?: Array<{ id: number; name: string; email: string }>;
  title2?: boolean;
}

// TODO: Rich text editor and Emoji input changes
// TODO: Optimization of large data set

const CommentView = (props: Props) => {
  const {
    comments = [],
    user = {},
    onSubmit,
    isSubmitting = false,
    stakeholder = [],
    title2 = false,
  } = props;

  return (
    <Box component={Paper} p={2}>
      {title2 ? (
        <Typography variant="h5" fontWeight="normal" component="div" py={1}>
          Recent Activity
        </Typography>
      ) : (
        <Typography variant="subtitle2" pb={1}>
          Recent Activity
        </Typography>
      )}

      {stakeholder.length > 0 && (
        <InputField
          mode="COMMENT"
          user={{
            name: user.name,
            id: user.id,
            profilePicUrl: user.profilePic,
          }}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          mentionUsers={stakeholder}
          isRichTextInput
        />
      )}
      <Box pt={2} mt={3} pb={1} ml={'-5px'} borderTop="1px solid #edeff1">
        {isSubmitting ? (
          <CircularProgress size={24} />
        ) : (
          comments.map((comment) => (
            <Comment
              key={`comment-view-${comment.id}`}
              comment={comment}
              user={user}
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
              mentionUsers={stakeholder}
            />
          ))
        )}
      </Box>
    </Box>
  );
};

export default CommentView;
