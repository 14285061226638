import queryString from 'query-string';

import { axiosInstance } from '../../utils/axios.instance';

export const fetchCandidates = (filters: any) => {
  const filterData: any = {
    searchTerm: filters.searchTerm || '',
    location: filters.location || '',
    functionalArea: filters.functionalArea
      ? filters.functionalArea.map((functionalArea: any) => functionalArea.id)
      : null,

    isEmailRequired: filters.isEmailRequired || false,
    isLinkedinRequired: filters.isLinkedinRequired || false,
    isPhoneRequired: filters.isPhoneRequired || false,
    isResumeRequired: filters.isResumeRequired || false,
  };
  if (filters.experience && filters.experience[1]) {
    filterData['exp[min]'] = filters.experience[0] || 0;
    filterData['exp[max]'] = filters.experience[1] || 100;
  }
  if (filters.salary && filters.salary[1]) {
    filterData['salary[min]'] = filters.salary[0] || 0;
    filterData['salary[max]'] = filters.salary[1] || 100;
  }
  const query = queryString.stringify(
    { page: filters.page, clientId: filters.clientId, ...filterData },
    {
      skipEmptyString: true,
      skipNull: true,
    }
  );

  return axiosInstance.get(`/api/v2/job-application/candidates?${query}`);
};

export const fetchCandidatesByPostings = (jobId: number, filters?: any) => {
  const query = queryString.stringify(filters, {
    skipEmptyString: true,
    skipNull: true,
  });

  return axiosInstance({
    method: 'GET',
    url: `/api/v3/job-application/${jobId}?${query}`,
  });
};

export const updateCandidateStage = (candidateId: number, stageId: number) =>
  axiosInstance({
    method: 'PUT',
    url: '/api/v2/hiring-workflow/candidate',
    data: {
      candidateId,
      stageId,
    },
  });
