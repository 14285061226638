import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import parse from 'html-react-parser';
import draftToHtml from 'draftjs-to-html';

interface CollapsibleTextProps {
  text: string;
  maxLength?: number;
}

const cleanEditorHtml = (html: string) => {
  return html
    .replace(/<br\s*\/?>/gi, '') // Remove extra <br> tags
    .replace(/&nbsp;/g, ' ') // Replace non-breaking spaces with regular spaces
    .replace(/\s{2,}/g, ' ') // Collapse multiple spaces into one
    .replace(/<p>\s*<\/p>/g, ''); // Remove empty paragraphs
};

const CollapsibleText: React.FC<CollapsibleTextProps> = ({
  text,
  maxLength = 80,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const shouldTruncate = text.length > maxLength;

  const htmlText = parse(cleanEditorHtml(text), { trim: true });
  const stripText = cleanEditorHtml(text)
    .replace(/<[^>]*>/g, '')
    .trim();

  return (
    <Box>
      <Typography
        variant="body2"
        style={{
          whiteSpace: isExpanded || !shouldTruncate ? 'pre-wrap' : 'none',
        }}
        sx={{
          '& p': {
            margin: '0px !important',
          },
        }}
      >
        {isExpanded || !shouldTruncate
          ? htmlText
          : `${stripText.substring(0, maxLength)}...`}
        {shouldTruncate && (
          <span
            onClick={toggleExpand}
            style={{
              color: 'blue',
              cursor: 'pointer',
              marginLeft: 5,
              textDecoration: 'underline',
            }}
          >
            {isExpanded ? 'Read Less' : 'Read More'}
          </span>
        )}
      </Typography>
    </Box>
  );
};

export default CollapsibleText;
