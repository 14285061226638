import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import WorkspaceMailAPI from '../../services/workspace-mail.service';

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

interface ThreadEmails {
  subject: string;
  emails: Array<any>;
  labels: Array<any>;
}

interface IState {
  loading: ILoading;
  error: string;
  data: ThreadEmails | null;
}

const fetchThreadMail = createAsyncThunk(
  'mail-thread/fetch-mail',
  async (threadId: number): Promise<any> => {
    return await WorkspaceMailAPI.getThreadMails(threadId);
  }
);

const assignLabelToEmail = createAsyncThunk(
  'mail-thread/assign-label',
  async (payload: { threadId: number; labelIds: number[] }, { dispatch }) => {
    await WorkspaceMailAPI.assignLabelsToMailThread(payload.threadId, {
      labelIds: payload.labelIds,
    });
    dispatch(fetchThreadMail(payload.threadId));
  }
);

const deleteEmailLabel = createAsyncThunk(
  'mail-thread/delete-label',
  async (payload: { threadId: number; labelId: number }, { dispatch }) => {
    await WorkspaceMailAPI.removeLabelFromMailThread(
      payload.threadId,
      payload.labelId
    );
    dispatch(fetchThreadMail(payload.threadId));
  }
);

const initialState: IState = {
  loading: 'idle',
  error: '',
  data: null,
};

const threadSlice = createSlice({
  name: 'mail-thread',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchThreadMail.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchThreadMail.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchThreadMail.rejected, (state) => {
        state.loading = 'failed';
      }),
});

export default threadSlice.reducer;

export { fetchThreadMail, assignLabelToEmail, deleteEmailLabel };
