import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Paper,
  Button,
  Stack,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useFormik } from 'formik';

import SlideModal from '../core/SlideModal';

interface AddLabelModelProps {
  open: boolean;
  onClose: () => void;
  onSubmit: ({ label }: { label: string }) => void;
  label?: string;
}

const AddLabelModel: React.FC<AddLabelModelProps> = ({
  open = false,
  onClose,
  onSubmit,
  label = '',
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      label: label || '',
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  return (
    <SlideModal open={open} onClose={onClose}>
      <Box component={Paper}>
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h4">New label</Typography>
          <Typography variant="subtitle2" mt={4}>
            Please enter a new label name
          </Typography>
          <TextField
            placeholder="Your label"
            size="small"
            fullWidth
            name="label"
            value={formik.values.label}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <Stack
            direction="row"
            alignSelf="flex-end"
            justifyContent="flex-end"
            gap={2}
            mt={3}
          >
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={formik.values.label.length == 0}
            >
              Create
            </Button>
          </Stack>
        </form>
      </Box>
    </SlideModal>
  );
};

export default AddLabelModel;
