import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import DashboardAPI from '../../services/dashboard.service';

interface ICVByStage {
  data: {
    job: Array<{ id: number; title: string; stages: Record<string, number> }>;
    channel: Array<{
      id: number;
      title: string;
      stages: Record<string, number>;
    }>;
    user: Array<{ id: number; title: string; stages: Record<string, number> }>;
  };
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string;
}

const fetchCVGroupByStage = createAsyncThunk(
  'cv-movement-group/fetchCVGroupByStage',
  async (payload: any): Promise<any> => {
    const { workspaceId = '', start = '', end = '' } = payload;
    return await DashboardAPI.fetchCVGroupStage(workspaceId, start, end);
  }
);

const initialState: ICVByStage = {
  data: {
    job: [],
    channel: [],
    user: [],
  },
  loading: 'idle',
  error: '',
};

const cvGroupStageSlice = createSlice({
  name: 'cv-movement-group',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchCVGroupByStage.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchCVGroupByStage.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchCVGroupByStage.rejected, (state) => {
        state.loading = 'failed';
        state.error = 'something went wrong!';
      }),
});

export default cvGroupStageSlice.reducer;

export { fetchCVGroupByStage };
