import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Button,
  Box,
  Modal as MUIModal,
  Alert,
} from '@mui/material';
import _, { isArray } from 'lodash';
import randomString from 'randomstring';
import dayjs, { Dayjs } from 'dayjs';

import { IEvent } from '../../types/calendar';
import CalendarAPI from '../../redux/services/calendar.service';
import { showSnackbar } from '../../redux/features/dashboard/alertSlice';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { updateCandidateStage } from '../../redux/services/fetch-candidates';
import { candidateById } from '../../redux/features/applications/applicationSlice';
import CandidateWorkflowChangeInput from './CandidateWorkflowChangeInput';
import {
  fetchRecruiterActions,
  resetActions,
  addRecruiterAction,
  shareProfileViaEmail,
} from '../../redux/features/candidate/recruiter-action.slice';
import Modal from '../modal';
import { sendJobDescriptionEmail } from '../../redux/services/jobs';
import EmailComposer from '../email-composer';
import AddEventModal from '../event-calendar/AddEventModal.react';
import SelectStakeholderInput from './SelectStakeholderInput';
import { fetchCandidateTimeline } from '../../redux/features/candidate/timeline.slice';
import ReasonTextArea from './ReasonTextArea';
import SimpleDatePicker from '../form-fields/SimpleDatePicker';

interface Props {
  onTaskSubmit: any;
  onTaskTypeSubmit: any;
  loading: boolean;
  applicationId: number;
  jobId: number;
  taskTLFeedback: any;
  taskCLFeedback: any;
  onOpenEventModal?: any;
  stakeholders: Array<any>;
  hiringWorkflow: Array<any>;
  openFeedbackForm: () => void;
}

interface ExposedMethods {
  handleDNPAction: () => void;
}

interface IHiringStateOptions {
  id: number;
  name: string;
  slug: string;
}
interface IHiringStageOptions {
  id: number;
  jobId: number;
  sequenceNo: number;
  stage: {
    id: number;
    name: string;
    slug: string;
    states: IHiringStateOptions[];
  };
}

interface IStage {
  id: number;
  name: string;
  slug: string;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 600,
  height: '100%',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
};

const disalogContentMapping = {
  dnp: 'Are you sure you want to mark this candidate as DNP (Did Not Pick the Call)?',
  'feedback-added': '',
  jdshared: 'Do you want to share the job description with the candidate?',
  nr: 'Are you sure you want to mark this candidate as not relevant?',
  reject: 'Are you certain you want to mark this candidate as rejected?',
  backout: 'Are you sure you want to mark this candidate as backed out?',
  offer: "Are you sure you want to mark this candidate as 'Offered'?",
  shortlisted: "Are you sure you want to mark this candidate as 'Shortlisted'?",
  'screen-review-accept':
    "Are you sure you want to mark this candidate as 'Screen Accept'?",
  joined: "Are you sure you want to mark this candidate as 'Joined'?",
};

type eventSlug =
  | 'dnp'
  | 'feedback-added'
  | 'jdshared'
  | 'nr'
  | 'profile-shared'
  | 'clientReview'
  | 'interview-schedule'
  | 'reject'
  | 'backout'
  | 'offer'
  | 'shortlisted'
  | 'screen-review-accept'
  | 'screen-review-reject'
  | 'offer-accepted'
  | 'offer-rejected'
  | 'probation-completion-date'
  | 'joined'
  | 'update-joining-date'
  | null;

export default forwardRef<ExposedMethods, Props>((props, ref) => {
  const [pendingAction, setPendingAction] = useState<null | eventSlug>(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState<string | null>('');
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);
  const [isDateModalOpen, setIsDateModalOpen] = useState<boolean>(false);
  const [openEventModal, setOpenEventModal] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [hiringStageOptions, setHiringStageOptions] = useState<
    IHiringStageOptions[]
  >([]);
  const [currentStage, setCurrentStage] = useState<IStage | null>(null);
  const [stackholderOptions, setStakehoderOptions] = useState<any>([]);
  const { candidate } = useAppSelector((state) => state.application);
  const { hiringWorkflow } = useAppSelector((state) => state.hiringWorkflow);
  const { actions = [], loading = 'idle' } = useAppSelector(
    (state) => state.recruiterActions
  );
  const dispatch = useAppDispatch();

  const selectedStage: any = candidate?.details?.hiringStatus?.stageId || 0;
  const selectedState: any = candidate?.details?.hiringStatus?.stateId || 0;

  useEffect(() => {
    if (candidate.details?.id) {
      dispatch(fetchRecruiterActions(Number(candidate.details.id)));
    }
    return () => {
      dispatch(resetActions());
    };
  }, [dispatch, candidate.details?.id, selectedStage]);

  useEffect(() => {
    setHiringStageOptions(hiringWorkflow.data);
    if (hiringWorkflow.data && hiringWorkflow.data.length) {
      const currentStage = hiringWorkflow.data.filter(
        (pipeline: any) => pipeline.stage.id == selectedStage
      );
      setCurrentStage(currentStage[0].stage);
    }
  }, [hiringWorkflow.loading == 'succeeded', selectedStage]);

  useEffect(() => {
    const stakeholderOptions = props.stakeholders.map((stakeholder) => ({
      label: stakeholder.email,
      value: stakeholder.id,
    }));
    setStakehoderOptions(stakeholderOptions);
  }, [props.stakeholders]);

  const handleWorkflowChange = async (stageId: number) => {
    const candidateId = candidate.details.id;
    if (!candidateId) return;

    try {
      await updateCandidateStage(candidateId, stageId);
      dispatch(candidateById(Number(candidateId)));
      dispatch(fetchCandidateTimeline(Number(candidateId)));
    } catch (error) {
      console.error('Error updating candidate stage:', error);
    }
  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleAccept = () => {
    handlePendingAction(pendingAction);
    setDialogMessage('');
    setIsConfirmDialogOpen(false);
  };

  const handleDNPAction = () => {
    setDialogMessage(disalogContentMapping['dnp']);
    setIsConfirmDialogOpen(true);
    setPendingAction('dnp');
  };

  useImperativeHandle(ref, () => ({
    handleDNPAction,
  }));

  const handleActionClick = (slug: eventSlug) => {
    switch (slug) {
      // case 'dnp':
      case 'jdshared':
      case 'nr':
      case 'backout':
      case 'offer':
      case 'shortlisted':
      case 'screen-review-accept':
      case 'joined':
        setDialogMessage(disalogContentMapping[slug]);
        setIsConfirmDialogOpen(true);
        setPendingAction(slug);
        break;
      case 'profile-shared':
      case 'clientReview':
        setPendingAction(slug);
        setIsReviewModalOpen(true);
        break;
      case 'feedback-added':
        props.openFeedbackForm();
        break;
      case 'interview-schedule':
        setOpenEventModal(true);
        setPendingAction(slug);
        break;
      case 'screen-review-reject':
      case 'reject':
      case 'offer-rejected':
        setIsCommentModalOpen(true);
        setPendingAction(slug);
        break;
      case 'offer-accepted':
      case 'probation-completion-date':
      case 'update-joining-date':
        setIsDateModalOpen(true);
        setPendingAction(slug);
        break;
      default:
    }
  };

  const handlePendingAction = async (action: eventSlug) => {
    switch (action) {
      case 'dnp':
      case 'nr':
      case 'backout':
      case 'offer':
      case 'shortlisted':
      case 'screen-review-accept':
      case 'joined':
        if (candidate.details.id) {
          dispatch(
            addRecruiterAction({
              event: {
                jobId: candidate.details.jobId,
                eventSulg: action,
              },
              candidateId: candidate.details.id,
            })
          );
        }
        break;
      case 'jdshared':
        const { email = '', jobId = '', id = '' } = candidate.details;
        await sendJobDescriptionEmail({
          recipients: [email],
          subject: 'Job Description',
          message: '<p>Hi</p> <p>Please find job description</p>',
          jobId: jobId,
        });
        dispatch(fetchRecruiterActions(Number(id)));
        dispatch(candidateById(Number(id)));
        dispatch(fetchCandidateTimeline(Number(id)));
        break;
      default:
        console.log('Event is not handled!');
    }
    setPendingAction(null);
  };

  const handleEmailModalClose = () => {
    setIsEmailModalOpen(false);
  };

  const handleEmailSubmit = (values: any) => {
    if (
      candidate.details?.id &&
      pendingAction &&
      ['clientReview', 'profile-shared'].includes(pendingAction)
    ) {
      dispatch(
        shareProfileViaEmail({
          data: {
            emailPayload: values,
            event: {
              slug: pendingAction,
              candidateId: candidate.details.id,
              applicantId: candidate.details.applicantId,
              jobId: candidate.details.jobId,
            },
          },
          candidateId: candidate.details.id,
        })
      );
      handleEmailModalClose();
      setPendingAction(null);
    }
  };

  const handleCloseEventModal = () => {
    setOpenEventModal(false);
  };

  const handleAddEvent = (event: any) => {
    const eventRequest: IEvent = {
      summary: event.title,
      description: event.description,
      start: {
        dateTime: dayjs(
          `${event.date} ${event.startTime}`,
          'YYYY-MM-DD hh:mm:A'
        ).toISOString(),
        timeZone: 'utc',
      },
      end: {
        dateTime: dayjs(
          `${event.date} ${event.startTime}`,
          'YYYY-MM-DD hh:mm:A'
        )
          .add(event.duration, 'minutes')
          .toISOString(),
        timeZone: 'utc',
      },
      attendees: event.attendees.map((user: any) => ({ email: user })),
    };
    if (event.googleConfrence) {
      eventRequest['conferenceData'] = {
        createRequest: {
          conferenceSolutionKey: {
            type: 'hangoutsMeet',
          },
          requestId: randomString.generate(10),
        },
      };
    }
    CalendarAPI.addEvent(eventRequest)
      .then((response) => {
        if (candidate.details.id) {
          dispatch(
            addRecruiterAction({
              event: {
                jobId: candidate.details.jobId,
                eventSulg: 'interview-schedule',
                summary: `${eventRequest.summary} with ${eventRequest.attendees
                  ?.map((attendees) => attendees.email)
                  .join(', ')} on ${dayjs(eventRequest.start.dateTime).format(
                  'DD-MM-YYYY hh:mm A'
                )}`,
              },
              candidateId: candidate.details.id,
            })
          );
        }
        setOpenEventModal(false);
        setPendingAction(null);
      })
      .catch((error) => {
        console.log('err', error);
        dispatch(
          showSnackbar({
            open: true,
            message: 'Oops! Something went wrong. Please try again later.',
            severity: 'error',
          })
        );
      });
  };

  const handleShareReview = (values: any, isSendToClient: boolean) => {
    if (
      pendingAction &&
      candidate.details.id &&
      ['clientReview', 'profile-shared'].includes(pendingAction)
    ) {
      dispatch(
        shareProfileViaEmail({
          data: {
            emailPayload: {
              recipients: values.map(({ label, value }: any) => ({
                email: label,
                id: value,
              })),
              bcc: [],
              cc: [],
              subject: `${candidate.details.name}: Profile Shared for review`,
              message:
                '<p>Hi,</p><br/><br/> I hope this message finds you well.<br/> Please take a moment to review the profile and provide your feedback or any additional instructions at your earliest convenience.',
              attachment: [
                {
                  name: candidate.details.name,
                  s3url: candidate.details.s3Url,
                },
              ],
            },
            event: {
              slug: isSendToClient ? 'clientReview' : 'profile-shared',
              candidateId: candidate.details.id,
              jobId: candidate.details.jobId,
              applicantId: candidate.details.applicantId,
            },
          },
          candidateId: candidate.details.id,
        })
      );
      setPendingAction(null);
      setIsReviewModalOpen(false);
    }
  };

  const handleCloseCommentBox = () => {
    setIsCommentModalOpen(false);
    setPendingAction(null);
  };

  const handleSubmitComment = (comment: string) => {
    if (candidate.details.id) {
      dispatch(
        addRecruiterAction({
          event: {
            jobId: candidate.details.jobId,
            eventSulg: pendingAction,
            comment,
          },
          candidateId: candidate.details.id,
        })
      );
    }
    setIsCommentModalOpen(false);
    setPendingAction(null);
  };
  const handleSaveDate = () => {
    if (candidate.details.id && selectedDate && selectedDate.isAfter()) {
      dispatch(
        addRecruiterAction({
          event: {
            jobId: candidate.details.jobId,
            eventSulg: pendingAction,
            date: selectedDate,
          },
          candidateId: candidate.details.id,
        })
      );
    }
    setIsDateModalOpen(false);
    setPendingAction(null);
  };

  const dateLabel =
    pendingAction === 'offer-accepted'
      ? 'Select Joining Date'
      : pendingAction === 'probation-completion-date'
      ? 'Select Probation End Date'
      : 'Select Date';

  const renderActionAndAlert = () => {
    // if (currentStage?.slug === 'offer' && candidate.details.joiningDate) {
    //   return (
    //     <Alert severity="info">
    //       Joining date is {candidate.details.joiningDate}
    //     </Alert>
    //   );
    // }

    if (currentStage?.slug === 'joined' && candidate.details.probationEndDate) {
      return (
        <Alert severity="info">
          Probation end date is {candidate.details.probationEndDate}
        </Alert>
      );
    }

    if (currentStage?.slug == 'screening' && actions.length == 0) {
      return <Alert severity="warning">Waiting for peer review.</Alert>;
    }

    return actions.map((action) => (
      <Button
        key={`recruiter-action-${action.id}`}
        variant="outlined"
        onClick={() => handleActionClick(action.slug)}
        size="small"
      >
        {action.label}
      </Button>
    ));
  };

  return (
    <Box>
      <Modal
        open={isConfirmDialogOpen}
        onAccept={handleAccept}
        isActions
        acceptBtnText="Continue"
        handleClose={handleConfirmDialogClose}
      >
        <Typography variant="body2">{dialogMessage}</Typography>
      </Modal>
      {isEmailModalOpen && (
        <EmailComposer
          closeBtn
          handleClose={handleEmailModalClose}
          handleEmailSubmit={handleEmailSubmit}
          attachment={[
            { name: candidate.details.name, s3url: candidate.details.s3Url },
          ]}
        />
      )}
      <MUIModal open={isReviewModalOpen}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 600,
            overflow: 'hidden',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          <SelectStakeholderInput
            options={stackholderOptions}
            onSubmit={handleShareReview}
            onCancel={() => setIsReviewModalOpen(false)}
          />
        </Box>
      </MUIModal>
      <MUIModal open={isCommentModalOpen}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 600,
            overflow: 'hidden',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
          }}
        >
          <ReasonTextArea
            onClose={handleCloseCommentBox}
            onSubmit={handleSubmitComment}
          />
        </Box>
      </MUIModal>
      <MUIModal open={isDateModalOpen}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'hidden',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            gap: 2,
          }}
        >
          <SimpleDatePicker
            label={dateLabel}
            value={selectedDate}
            onDateChange={(value) => setSelectedDate(value)}
          />
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gap={2}
            mt={2}
          >
            <Button
              variant="contained"
              disabled={!selectedDate}
              onClick={handleSaveDate}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => setIsDateModalOpen(false)}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </MUIModal>
      <AddEventModal
        open={openEventModal}
        handleClose={handleCloseEventModal}
        onAddEvent={handleAddEvent}
        event={{
          extendedProps: {
            raw: {
              attendees: [{ email: candidate.details.email }],
            },
          },
        }}
      />
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" component="div">
            Recruiter Actions
          </Typography>
          <Box py={1}>
            {isArray(hiringStageOptions) && (
              <CandidateWorkflowChangeInput
                selectedStage={selectedStage}
                selectedState={selectedState}
                hiringWorkflow={hiringStageOptions}
                onSubmit={handleWorkflowChange}
              />
            )}
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            {actions.length > 0 && (
              <Typography variant="body2">Your Next Actions</Typography>
            )}
            <Stack flexWrap="wrap" gap={1} direction="row">
              {renderActionAndAlert()}
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});
