import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import CommentAPI from '../../services/comment.service';

interface IDefaultState {
  commentList: {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    data: Array<any>;
  };
  summaryComment: {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    data: {
      total: number;
      list: Array<any>;
      hasNext: boolean;
    };
  };
}

const initialState: IDefaultState = {
  commentList: {
    loading: 'idle',
    data: [],
  },
  summaryComment: {
    loading: 'idle',
    data: {
      total: 0,
      list: [],
      hasNext: false,
    },
  },
};

export const fetchComments = createAsyncThunk(
  'comment/fetch',
  async (id: number) => {
    const response = await CommentAPI.fetchComment(id);
    return response.data;
  }
);

export const addComment = createAsyncThunk(
  'comment/addComment',
  async (data: any) => {
    const response = await CommentAPI.addComment(data);
    return response.data;
  }
);

export const updateComment = createAsyncThunk(
  'comment/updateComment',
  async (payload: any, { dispatch }) => {
    const response = await CommentAPI.updateComment(
      payload.commentId,
      payload.data
    );
    return response.data;
  }
);

export const likeComment = createAsyncThunk(
  'comment/like',
  async (payload: { commentId: number }) => {
    const response = await CommentAPI.addlikeReactionOnComment(payload);
    return response.data;
  }
);

export const commentSummary = createAsyncThunk(
  'comment/fetch-comment-summary',
  async (payload: { page: number; start: string; end: string }) => {
    const response = await CommentAPI.fetchCommentSummary(
      payload.start,
      payload.end,
      payload.page
    );
    return response.data;
  }
);

const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    setComments: (state, action) => {
      return {
        ...state,
        commentList: {
          loading: 'succeeded',
          data: action.payload,
        },
      };
    },
    resetCommentSummary: (state) => {
      return {
        ...state,
        summaryComment: {
          loading: 'idle',
          data: {
            total: 0,
            list: [],
            hasNext: false,
          },
        },
      };
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchComments.pending, (state) => {
        state.commentList.loading = 'pending';
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.commentList.loading = 'succeeded';
        state.commentList.data = action.payload;
      })
      .addCase(fetchComments.rejected, (state) => {
        state.commentList.loading = 'failed';
      })
      .addCase(addComment.pending, (state) => {
        state.commentList.loading = 'pending';
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.commentList.loading = 'succeeded';
        state.commentList.data = action.payload;
      })
      .addCase(addComment.rejected, (state) => {
        state.commentList.loading = 'failed';
      })
      .addCase(updateComment.pending, (state) => {
        state.commentList.loading = 'pending';
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        state.commentList.loading = 'succeeded';
        state.commentList.data = action.payload;
      })
      .addCase(updateComment.rejected, (state) => {
        state.commentList.loading = 'failed';
      })
      .addCase(likeComment.fulfilled, (state, action) => {
        state.commentList.data = action.payload;
      })
      .addCase(commentSummary.pending, (state) => {
        state.summaryComment.loading = 'pending';
      })
      .addCase(commentSummary.fulfilled, (state, action) => {
        state.summaryComment.loading = 'succeeded';
        state.summaryComment.data.list = [
          ...state.summaryComment.data.list,
          ...action.payload.list,
        ];
        state.summaryComment.data.hasNext = action.payload.hasNext;
      })
      .addCase(commentSummary.rejected, (state) => {
        state.summaryComment.loading = 'failed';
      }),
});

export const { setComments, resetCommentSummary } = commentSlice.actions;
export default commentSlice.reducer;
