import { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
} from '@mui/material';
import _ from 'lodash';

const defaultState: any = {};

const InvitePermissions = ({
  values,
  roles,
  handleChange,
  setFieldValue,
}: any) => {
  const [selectedRole, setSelectRole] = useState(defaultState);

  const makeScopeDefaultChecked = (permission: string) => {
    const _role = _.find(roles, (role) => role.slug === permission);
    const scope: any = {};
    if (_role?.scopes?.length) {
      _role.scopes.forEach((element: any) => {
        scope[element.slug] = true;
      });

      setFieldValue('scope', scope);
      setSelectRole(_role.scopes);
    }
  };
  useEffect(() => {
    if (roles.length && values.permissions) {
      makeScopeDefaultChecked(values.permissions);
    }
  }, [roles, values.permissions]);
  return (
    <>
      <FormControl margin="dense">
        <FormLabel id="role-radio-buttons-group-label">Select a role</FormLabel>
        <RadioGroup
          aria-labelledby="role-radio-buttons-group-label"
          name="permissions"
          row
          value={values.permissions}
          onChange={(e) => {
            makeScopeDefaultChecked(e.target.value);
            handleChange(e);
          }}
        >
          {roles.map((role: any) => (
            <FormControlLabel
              key={`role-${role.id}`}
              value={role.slug}
              control={<Radio size="small" />}
              label={role.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {values.permissions && !_.isEmpty(selectedRole) ? (
        <FormControl>
          <FormLabel component="label">Select role scope</FormLabel>
          <FormGroup row>
            {selectedRole.map((roleScope: any) => (
              <FormControlLabel
                key={`role-scope-${roleScope.id}`}
                control={
                  <Checkbox
                    checked={
                      values['scope'] ? values['scope'][roleScope.slug] : false
                    }
                    name={`scope.${roleScope.slug}`}
                    onChange={(e) => {
                      setFieldValue(
                        `scope[${roleScope.slug}]`,
                        e.target.checked
                      );
                    }}
                  />
                }
                label={roleScope.name}
              />
            ))}
          </FormGroup>
        </FormControl>
      ) : (
        <noscript />
      )}
    </>
  );
};

export default InvitePermissions;
