import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import WorkspaceMailAPI from '../../services/workspace-mail.service';

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

interface IState {
  loading: ILoading;
  error: string;
  data: {
    total: number;
    pageSize: number;
    page: number;
    mails: Array<any>;
  } | null;
  categoryInbox: {
    loading: ILoading;
    error: string;
    data: {
      total: number;
      pageSize: number;
      page: number;
      mails: Array<any>;
    } | null;
  };
  category: {
    loading: ILoading;
    error: string;
    data: Array<{ id: number; name: string }> | null;
  };
}

const fetchInboxMail = createAsyncThunk(
  'mail-inbox/fetch-inbox',
  async (payload: { page?: number }): Promise<any> => {
    const { page = 1 } = payload;
    return await WorkspaceMailAPI.getInbox(page);
  }
);

const fetchCategoryInboxMail = createAsyncThunk(
  'mail-inbox/fetch-category-inbox',
  async (payload: { categoryId: number; page?: number }): Promise<any> => {
    const { categoryId, page = 1 } = payload;
    return await WorkspaceMailAPI.getCategoryInbox(categoryId, page);
  }
);

const fetchCategory = createAsyncThunk(
  'mail-inbox/fetch-inbox-category',
  async (): Promise<any> => {
    return await WorkspaceMailAPI.emailCategory();
  }
);

const initialState: IState = {
  loading: 'idle',
  error: '',
  data: null,
  categoryInbox: {
    loading: 'idle',
    error: '',
    data: null,
  },
  category: {
    loading: 'idle',
    error: '',
    data: null,
  },
};

const inboxSlice = createSlice({
  name: 'mail-inbox',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchInboxMail.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchInboxMail.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchInboxMail.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(fetchCategory.pending, (state) => {
        state.category.loading = 'pending';
      })
      .addCase(fetchCategory.fulfilled, (state, action) => {
        state.category.loading = 'succeeded';
        state.category.data = action.payload;
      })
      .addCase(fetchCategory.rejected, (state) => {
        state.category.loading = 'failed';
      })
      .addCase(fetchCategoryInboxMail.pending, (state) => {
        state.categoryInbox.loading = 'pending';
      })
      .addCase(fetchCategoryInboxMail.fulfilled, (state, action) => {
        state.categoryInbox.loading = 'succeeded';
        state.categoryInbox.data = action.payload;
      })
      .addCase(fetchCategoryInboxMail.rejected, (state) => {
        state.categoryInbox.loading = 'failed';
      }),
});

export default inboxSlice.reducer;

export { fetchInboxMail, fetchCategoryInboxMail, fetchCategory };
