import { ReactRenderer } from '@tiptap/react';
import tippy, { Instance as TippyInstance } from 'tippy.js';
import { SuggestionProps, SuggestionKeyDownProps } from '@tiptap/suggestion';

import { MentionList } from './MentionList';

interface User {
  id: string;
  label: string;
}

export default (users: User[]) => {
  return {
    items: ({ query }: { query: string }): User[] =>
      users
        .filter((user) =>
          user.label.toLowerCase().startsWith(query.toLowerCase())
        )
        .slice(0, 5),

    render: () => {
      //@ts-ignore
      let reactRenderer: ReactRenderer<MentionList> | null = null;
      let popup: TippyInstance | null = null;

      return {
        onStart: (props: SuggestionProps<User>) => {
          if (!props.clientRect) return;
          console.log('props', props);
          reactRenderer = new ReactRenderer(MentionList, {
            props,
            editor: props.editor,
          });
          //@ts-ignore
          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })[0]; // Get the first Tippy instance
        },

        onUpdate(props: SuggestionProps<User>) {
          reactRenderer?.updateProps(props);

          if (!props.clientRect || !popup) return;

          popup.setProps({
            //@ts-ignore
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props: SuggestionKeyDownProps) {
          if (props.event.key === 'Escape') {
            popup?.hide();
            return true;
          }

          return reactRenderer?.ref?.onKeyDown(props) ?? false;
        },

        onExit() {
          popup?.destroy();
          reactRenderer?.destroy();
        },
      };
    },
  };
};
