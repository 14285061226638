import React, { FC } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import parse from 'html-react-parser';

const formatDate = (date: string | Date): string => {
  const now = dayjs();
  const givenDate = dayjs(date);

  if (
    givenDate.isBefore(now, 'day') &&
    givenDate.isSame(now.subtract(1, 'day'), 'day')
  ) {
    return givenDate.format('MMM D');
  } else if (givenDate.isSame(now, 'day')) {
    return givenDate.format('h:mm A');
  } else {
    return givenDate.format('MMM D');
  }
};

interface MailInboxAccordionProps {
  mails: Array<any>;
}

const MailInboxAccordion: FC<MailInboxAccordionProps> = ({ mails }) => {
  console.log('mails', mails);
  return (
    <Box>
      {mails.map((mail) => (
        <Accordion key={`mail-accodion-${mail.threadId}`}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography component="span">{mail.subject}</Typography>
              <Typography component="span">
                {formatDate(mail.createdAt)}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>{parse(mail.emails[0].body)}</Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default MailInboxAccordion;
