import React from 'react';
import { Box, styled, Theme } from '@mui/material';

const MarkDownViewBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  background: '#FFFFFF',
  padding: 8,
  outline: 'none',
  fontSize: '0.9em',
  '& table': {
    width: '100%',
    borderCollapse: 'collapse',
  },
  '& th, & td': {
    border: `1px solid ${theme.palette.divider}`,
    padding: '8px',
    textAlign: 'left',
  },
  '& th': {
    backgroundColor: theme.palette.grey[200],
    fontWeight: 'bold',
  },
  '& tr:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '& p': {
    marginBottom: 0,
  },
  '& ul, ol': {
    margin: 'inherit',
    padding: '1em 2em',
    '& p': {
      marginBottom: '0px',
    },
  },
  '& :first-child': {
    marginTop: 0,
  },
  '& .mention': {
    backgroundColor: '#3788D8',
    borderRadius: '0.2rem',
    padding: '0.1rem 0.1rem',
    color: '#FFFFFF',
  },
}));

interface MarkdownHTMLPreviewProps {
  content: string;
}

const MarkdownHTMLPreview: React.FC<MarkdownHTMLPreviewProps> = ({
  content,
}) => {
  return <MarkDownViewBox dangerouslySetInnerHTML={{ __html: content }} />;
};

export default MarkdownHTMLPreview;
