import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import JobApplicationAPI from '../../services/job-application.service';

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

interface IState {
  loading: ILoading;
  error: string;
  data: {
    total: number;
    list: Array<{}>;
  };
}

const fetchApplicant = createAsyncThunk(
  'active-application',
  async (payload: any): Promise<any> => {
    const {
      workspaceId = '',
      page = '',
      searchTerm = '',
      stage = '',
      channelId = '',
      recruiterId = '',
    } = payload;
    return JobApplicationAPI.fetchActiveApplication({
      workspaceId,
      page,
      searchTerm,
      stage,
      channelId,
      recruiterId,
    });
  }
);

const initialState: IState = {
  loading: 'idle',
  error: '',
  data: {
    total: 0,
    list: [],
  },
};

const activeApplications = createSlice({
  name: 'active-application',
  initialState,
  reducers: {
    resetList: (state) => {
      state.loading = 'idle';
      state.data.list = [];
      state.data.total = 0;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchApplicant.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchApplicant.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data.list = [...state.data.list, ...action.payload.list];
        state.data.total = action.payload.total;
      })
      .addCase(fetchApplicant.rejected, (state) => {
        state.loading = 'failed';
        state.error = 'Something went wrong!';
      }),
});

export default activeApplications.reducer;

export { fetchApplicant };

export const { resetList } = activeApplications.actions;
